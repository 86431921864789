// import React from "react";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import Typography from "@mui/material/Typography";
// import CardActions from "@mui/material/CardActions";
// import Button from "@mui/material/Button";
// import { useDispatch } from "react-redux";
// import { deleteAddress, getAddress, updateAddress } from "../../actions/userAction";

// const AddressCard = ({ address }) => {
//   const dispatch = useDispatch()
//   // const handleRemove = () => {
//   //   // console.log(address.id)
//   //   dispatch(deleteAddress(address.id))
//   //   dispatch(getAddress())
//   // }

//   // const handleSetDefault = () => {

//   //   dispatch(updateAddress({is_default:1,id:address.id}))
//   //   dispatch(getAddress())
//   // }

//   const handleRemove = async () => {
//     await dispatch(deleteAddress(address.id));
//     dispatch(getAddress());
//   };
  
//   const handleSetDefault = async () => {
//     await dispatch(updateAddress({ is_default: 1, id: address.id }));
//     dispatch(getAddress());
//   };


//   return (
//     <Card variant="outlined" sx={{ marginTop: 2,
//       marginBottom: 2, marginRight:2, minWidth: 345, minHeight: 200 ,maxWidth:318, maxHeight:264,fontSize:14 }}>
//       <CardContent>
//         <Typography variant="h6" component="div" style={{fontSize:14}}>
//           {address.name}
//         </Typography>
//         <Typography style={{fontSize:14}} color="text.secondary">{address.address}</Typography>
//         <Typography style={{fontSize:14}} color="text.secondary">{address.landmark}</Typography>
//         <Typography style={{fontSize:14}} color="text.secondary">
//           {address.area_name}, {address.city_name}
//         </Typography>
//         <Typography style={{fontSize:14}} color="text.secondary">{address.pincode}</Typography>
//         <Typography style={{fontSize:14}} color="text.secondary">
//           Phone number: {address.mobile}
//         </Typography>
//       </CardContent>
//       <CardActions>
//         <div className="CardAction" style={{backgroundColor:"orange",margin:"0 auto"}}>
//         <Button size="small" href={`/editaddress?id=${address.id}`}>Edit</Button>
//         <Button size="small" onClick={()=>handleRemove()}>Remove</Button>
//         {address.is_default === "0" ? (
//           <Button size="small" onClick={()=>handleSetDefault()}>Set as Default</Button>
//         ) : (
//           <Button size="small"> Default</Button>
 
//         )}
//         </div>
//       </CardActions>
//     </Card>
//   );
// };



// export default AddressCard
import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import { deleteAddress, getAddress, updateAddress } from "../../actions/userAction";

const AddressCard = ({ address, isSelected, onSelect  }) => {
  const dispatch = useDispatch();

  // console.log("isSelected",isSelected)
  

  const handleRemove = async () => {
    await dispatch(deleteAddress(address.id));
    dispatch(getAddress());
  };
  
  const handleSetDefault = async () => {
    await dispatch(updateAddress({ is_default: 1, id: address.id }));
    dispatch(getAddress());
  };

  return (
    <Card variant="outlined" sx={{ marginRight: 2,marginBottom: 2,marginTop: 2, minWidth: 345, minHeight: 200, maxWidth: 318, maxHeight: 264, fontSize: 14, display: 'flex', flexDirection: 'column' }}
    onClick={onSelect}
      style={{
        border: isSelected ? "2px solid blue" : "1px solid grey",
        // border:"2px solid orange"
      }}>
      <CardContent sx={{ overflow: 'hidden' }}>
        <Typography variant="h6" component="div" sx={{ fontSize: 14, mb: 1, fontWeight: 'bold' }}>
          {address.name}
        </Typography>
        <Box sx={{ overflow: 'auto', textOverflow: 'ellipsis', mb: 1 }}>
          <Typography color="text.secondary" sx={{ fontSize: 14 }} noWrap>
            {address.address}
          </Typography>
        </Box>
        <Box sx={{ overflow: 'auto', textOverflow: 'ellipsis', mb: 1 }}>
          <Typography color="text.secondary" sx={{ fontSize: 14 }}>
            {address.landmark}
          </Typography>
        </Box>
        <Typography color="text.secondary" sx={{ fontSize: 14 }}>
          {address.area_name}, {address.city_name}
        </Typography>
        <Typography color="text.secondary" sx={{ fontSize: 14 }}>
          {address.pincode}
        </Typography>
        <Typography color="text.secondary" sx={{ fontSize: 14 }}>
          Phone number: {address.mobile}
        </Typography>
      </CardContent>


      <CardActions sx={{ mt: 'auto' }} style={{backgroundColor:"#edede9"}}>
        <Box sx={{  display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Button style={{color:"#686763"}} size="small" href={`/editaddress?id=${address.id}`}>Edit</Button>
          <Button style={{color:"#686763"}}  size="small" onClick={handleRemove}>Remove</Button>
          {address.is_default === "0" ? (
            <Button  style={{color:"#686763"}} size="small" onClick={handleSetDefault}>Set Default</Button>
          ) : (
            <Button  style={{color:"#686763"}} size="small">Default</Button>
          )}
        </Box>
      </CardActions>
    </Card>
  );
};

export default AddressCard;
