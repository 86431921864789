import React, { useRef } from "react";
import OutlinedCard from "./Card.jsx";
import { Typography, IconButton } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "./ProductCarouselAnt.css";

const ProductCarouselAnt = ({ category }) => {
  // Create a ref for the scrollable container
  const carouselRef = useRef(null);

  // Function to handle scroll
  const scroll = (scrollOffset) => {
    carouselRef.current.scrollLeft += scrollOffset;
  };

  // Group products into chunks of three
  const productsChunks = [];
  for (let i = 0; i < category.product_details.length; i += 5) {
    productsChunks.push(category.product_details.slice(i, i + 5));
  }

  return (
    <div
      style={{
        // backgroundColor: "#f5f4ef",
        backgroundColor: "#efdba7",
        // borderRadius: "1.25rem",
        borderRadius: "0",

        // marginLeft: "2rem",
        // marginRight: "2rem",
        // marginTop: "2rem",
        marginBottom: "2rem",
        paddingBottom: "2rem",
        // paddingLeft: "2rem",
        // paddingRight: "2rem",
      }}
    >
      {/* {productsChunks.length > 0 && (
        <Typography
          variant="h4"
          component="h4"
          style={{
            padding: "2rem",
            color: "#1b1301",
            margin: "0",
            textAlign: "center",
            fontSize: "clamp(0.5rem, 0.3043rem + 3.4783vw, 2rem)",
            textTransform: "capitalize",
          }}
          className="SectionHeading"
        >
          {category.title}
        </Typography>
      )} */}

<div style={{padding:"1rem"}}>
      <Typography
        className="SectionTitle"
        style={{
          fontSize: "clamp(0.5rem, 0.3043rem + 3.4783vw, 1rem)",
          textTransform: "capitalize",
        }}
      >
        {category.title}
      </Typography>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton onClick={() => scroll(-400)}>
          <ArrowBackIosNewIcon />
        </IconButton>
        <div
          ref={carouselRef}
          style={{
            overflow: "auto",
            display: "flex",
            flexDirection: "row",
            width: "100%",
            scrollBehavior: "smooth",
          }}
        >
          {productsChunks.length > 0 &&
            productsChunks.map((chunk, chunkIndex) => (
              <div
                className="carouselContent"
                key={category.id + chunkIndex}
                style={{ display: "flex", flexDirection: "row" }}
              >
                {chunk.map((product) => (
                  <OutlinedCard
                    key={product.id}
                    product={product}
                    style={{ all: "unset", flex: "none", margin: "0 8px" }} // flex none to prevent flex shrink
                  />
                ))}
              </div>
            ))}
        </div>
        <IconButton onClick={() => scroll(400)}>
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default ProductCarouselAnt;
