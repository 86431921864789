import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';

const OrderCard = ({ order }) => {
  // Format date from "2023-12-21 18:09:47" to "21 December 2023"
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // console.log("OrderCard",order?.order_items[0].product_name);

  


  return (
    order?.order_items?.length > 0 &&( <Container style={{paddingTop:"2rem"}}>
    {/* <Card variant="outlined" style={{borderRadius:"15px",paddingBottom:"1rem"}}> */}
    <Card variant="outlined" style={{borderRadius:"0",paddingBottom:"1rem"}}>

      {/* <CardContent style={{padding:0, borderRadius:"15px"}}> */}
      <CardContent style={{padding:0, borderRadius:"0"}}>

        <div style={{backgroundColor:"#eff2f2",padding:"1rem"}}>
        <Box display="flex" justifyContent="space-between">
          <Typography color="textSecondary">
            ORDER PLACED
            <br />
            {formatDate(order?.date_added)}
          </Typography>
          <Typography color="textSecondary">
            TOTAL
            <br />
            ₹{order?.total}
          </Typography>
          <Typography color="textSecondary">
            SHIP TO
            <br />
            {order?.username}
          </Typography>
          <Typography>
            ORDER # {order?.id}
          </Typography>
        </Box>
        </div>

        <div style={{padding:"1rem"}}>

        <Box mt={2}>
          <Typography variant="h5" component="h2">
            {order?.active_status.charAt(0).toUpperCase() + order?.active_status.slice(1)}
          </Typography>
          {/* <Typography color="textSecondary">
            Package was handed to resident
          </Typography> */}
        </Box>

        <Box display="flex" alignItems="center" mt={2}>
          {order && <img src={order?.order_items[0]?.image} alt={order?.order_items[0]?.product_name} style={{ width: 100, height: 'auto', marginRight: 16 }} />}
          <Box>
            <Typography variant="h5" component="h3">
              {order && order?.order_items[0].product_name}
            </Typography>
            <Typography color="textSecondary">
              {order && order?.order_items[0].variant_name}
            </Typography>
            <Button variant="contained" href={`/orderdetails/${order?.id}`}>View your item</Button>
          </Box>
        </Box>
        </div> 

        {/* Add buttons like Track package, Return or replace items, etc. */}
      </CardContent>
    </Card>
    </Container>)
  );
};

export default OrderCard;
