import axios from "axios";
import {
  ADD_TO_WISHLIST_REQUEST,
  ADD_TO_WISHLIST_SUCCESS,
  ADD_TO_WISHLIST_FAILURE,
  REMOVE_FROM_WISHLIST_REQUEST,
  REMOVE_FROM_WISHLIST_SUCCESS,
  REMOVE_FROM_WISHLIST_FAILURE,
  GET_WISHLIST_PRODUCTS_REQUEST,
  GET_WISHLIST_PRODUCTS_SUCCESS,
  GET_WISHLIST_PRODUCTS_FAILURE,
  LOAD_FAVOURITES_REQUEST,
  LOAD_FAVOURITES_SUCCESS,
  LOAD_FAVOURITES_FAILURE,
} from "../constants/wishlistConstants";
import { CLEAR_ERRORS } from "../constants/wishlistConstants";
import { BASE_URL } from "../App";

// const BASE_URL = "https://webbackend.kitablovers.com";
// const BASE_URL = "http://webapptest.kitablovers.com";

// const BASE_URL = "http://localhost:4000";

// Action to add a product to the wishlist
export const addToWishlist = (user_id, product_id) => async (dispatch) => {
  try {
    dispatch({ type: ADD_TO_WISHLIST_REQUEST });

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestBody = {
      user_id: parseInt(user_id),
      product_id: parseInt(product_id),
    };

    const requestOptions = {
      body: requestBody,
    };
    const { data } = await axios.post(
      BASE_URL + `/app/middleware/v1/favourites`,
      requestOptions,
      {
        withCredentials: true, // Include this option in the Axios configuration
      }
    );

    dispatch({
      type: ADD_TO_WISHLIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADD_TO_WISHLIST_FAILURE,
      payload: error?.response?.data?.message,
    });
  }
};

// Action to remove a product from the wishlist
export const removeFromWishlist =
  (user_id, product_variant_id) => async (dispatch) => {
    try {
      dispatch({ type: REMOVE_FROM_WISHLIST_REQUEST });

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestBody = {
        user_id: parseInt(user_id),
        product_variant_id: parseInt(product_variant_id),
      };

      // Make an API request to remove the product from the wishlist
      const { data } = await axios.post(
        BASE_URL + `/app/middleware/v1/removefavourites`,
        {
          body: requestBody,
        },
        {
          withCredentials: true, // Include this option in the Axios configuration
        }
      );

      dispatch({
        type: REMOVE_FROM_WISHLIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: REMOVE_FROM_WISHLIST_FAILURE,
        payload: error?.response?.data?.message,
      });
    }
  };

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

export const fetchFavorites = () => {
  return (dispatch) => {
    // Dispatch the action to signal the start of the API call
    dispatch({
      type: GET_WISHLIST_PRODUCTS_REQUEST,
      status: "loading",
    });

    // Make the API call
    axios
      .get(BASE_URL + "/app/middleware/v1/favourites", {
        withCredentials: true, // Include this option in the Axios configuration
      })
      .then((response) => {
        const { data } = response;
        // console.log("response", data);
        dispatch({
          type: GET_WISHLIST_PRODUCTS_SUCCESS,
          status: "success",
          data: data,
        });
      })
      .catch((error) => {
        // console.log("error", error);
        dispatch({
          type: GET_WISHLIST_PRODUCTS_FAILURE,
          status: "failure",
          error: error.message,
        });
      });
  };
};

export const loadFavourites = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_FAVOURITES_REQUEST });

    const data = await axios.get(BASE_URL + `/app/middleware/v1/favourites`, {
      withCredentials: true,
    });

    dispatch({ type: LOAD_FAVOURITES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: LOAD_FAVOURITES_FAILURE,
      payload: error?.response?.data?.message,
    });
  }
};
