import { Box, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchOrders } from "../../actions/orderAction";
import OrderDetailsCard from "./OrderDetailsCard";
import { BASE_URL } from "../../App";

const OrderDetails = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const { id } = useParams();

  const [orderDetails, setOrderDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(BASE_URL+`/app/middleware/v1/fetchscanner/${id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch order details');
        }
        const data = await response.json();
        setOrderDetails(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchOrderDetails();
    }
  }, [id]);


  const orders = useSelector((state) => state.orders?.orders?.data);
  const addresses = useSelector((state) => state.address?.address?.data);

  const findOrderById = (orderId, orders) => {
    return orders && orders.find((order) => order.id === orderId);
  };

  const findAddressById = (orderId, orders) => {
    return orders && orders.find((order) => order.id === orderId);
  };

  const order = findOrderById(id, orders);
  // const address = findOrderById(orders, addresses);

  // console.log("orderDetails?.data?.scannerlink",orderDetails && orderDetails?.data?.scannerlink);

  return (
    <>
      <div>
        
        <Container style={{ minHeight: "50rem",padding:"2rem" }}>
        {/* <img src={orderDetails?.data?.scannerlink}/> */}
        <Typography variant="h3">Order Details</Typography>

          <OrderDetailsCard order={order} scanner={orderDetails && orderDetails?.data?.scannerlink}/>
          
        </Container>
      </div>
    </>
  );
};

export default OrderDetails;
