import {
  GET_WISHLIST_PRODUCTS_REQUEST,
  GET_WISHLIST_PRODUCTS_SUCCESS,
  GET_WISHLIST_PRODUCTS_FAILURE,
  ADD_TO_WISHLIST_REQUEST,
  ADD_TO_WISHLIST_SUCCESS,
  ADD_TO_WISHLIST_FAILURE,
  REMOVE_FROM_WISHLIST_REQUEST,
  REMOVE_FROM_WISHLIST_SUCCESS,
  REMOVE_FROM_WISHLIST_FAILURE,
  LOAD_FAVOURITES_REQUEST,
  LOAD_FAVOURITES_SUCCESS,
  LOAD_FAVOURITES_FAILURE,
} from "../constants/wishlistConstants";

const initialState = {
  wishlist: null,
  loading: false,
  error: null,
};

const wishlistReducer = (state = initialState, action) => {
  // console.log("action.type", action.type);
  // console.log("action.payload.data", action.payload && action.payload.data);
  switch (action.type) {
    case GET_WISHLIST_PRODUCTS_REQUEST:
    case ADD_TO_WISHLIST_REQUEST:
    case REMOVE_FROM_WISHLIST_REQUEST:
    case LOAD_FAVOURITES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_TO_WISHLIST_SUCCESS:
    case GET_WISHLIST_PRODUCTS_SUCCESS:
    case REMOVE_FROM_WISHLIST_SUCCESS:
    case LOAD_FAVOURITES_SUCCESS:
      return {
        ...state,
        loading: false,
        wishlist: action.payload.data.data.data,
        error: null,
      };
    case ADD_TO_WISHLIST_FAILURE:
    case GET_WISHLIST_PRODUCTS_FAILURE:
    case REMOVE_FROM_WISHLIST_FAILURE:
    case LOAD_FAVOURITES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default wishlistReducer;
