import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { Toaster, toast } from "react-hot-toast";
import { auth } from "../../../firebase";
import {
  Button,
  Container,
  IconButton,
  Input,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { BASE_URL } from "../../../App";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ChangePassword2 = () => {
  const { mobile } = useSelector((state) => state.user?.user || "");

  useEffect(() => {
    if (mobile) {
      setPh("+91" + mobile);
    }
  }, [mobile]);

  const navigate = useNavigate();

  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("+91" + mobile);
  // const [ph, setPh] = useState( "");

  const [showOtp, setShowOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [isPasswordChangeSuccessful, setIsPasswordChangeSuccessful] =
    useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  function onCaptchaVerify() {
    if (!window.RecaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => {},
        }
      );
    }
  }

  function onSignup(event) {
    event.preventDefault();
    setLoading(true);
    onCaptchaVerify();
    const appVerifier = window.recaptchaVerifier;
    const phoneNumber = "+" + ph;
try {
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOtp(true);
        toast.success("OTP Sent Successfully");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
        
      });
    } catch (error) {
      // console.log("error",error);
  
    }
    
  }

  function onOtpverify() {

    try {
      
    
    window.confirmationResult
      .confirm(otp)
      .then(async (result) => {
        setUser(result.user);
        setLoading(false);
        setShowOtp(false); // Hide OTP input
      })
      .catch((error) => {
        // console.log(error.message);
        toast.error(error.message);
      });

    } catch (error) {
    //  console.log("error",error); 
    }
  }

  //   function handleChangePassword() {
  //     // Add logic to change the password using Firebase Auth
  //     console.log("Password changed to:", newPassword);
  //     setIsPasswordChangeSuccessful(true); // Set the state to show success message
  //   }

  function handleChangePassword() {
    const url = BASE_URL + "/app/middleware/v1/resetpassword";
    const data = {
      mobile_no: ph.slice(2), // Assuming 'ph' holds the phone number
      new: newPassword,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("Success:", data);
        setIsPasswordChangeSuccessful(true); // Set the state to show success message
        // Handle additional success logic here
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle error logic here
      });
  }

  // useEffect(() => {
  //   let timeout;
  //   if (isPasswordChangeSuccessful) {
  //     timeout = setTimeout(() => {
  //       navigate("/"); // Redirects to the home page
  //     }, 5000); // 5000 milliseconds = 5 seconds
  //   }

  //   return () => clearTimeout(timeout); // Clear timeout if component unmounts
  // }, [isPasswordChangeSuccessful, navigate]);

  return (
    <>
      <Container style={{ minHeight: "55rem", margin: "0 auto" }}>
        <div className={`d-flex justify-content-center`}>
          <Toaster toastOptions={{ duration: 4000 }} />
          {!user && !isPasswordChangeSuccessful ? (
            <div
              className={`row position-absolute mt-5 bg-dark text-white p-5`}
            >
              {showOtp ? (
                <div>
                  <Typography
                    style={{ paddingBottom: "2rem", paddingTop: "2rem" }}
                  >
                    Enter Your OTP{" "}
                  </Typography>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    shouldAutoFocus
                    renderInput={(props) => (
                      <input
                        {...props}
                        style={{ width: "30px", marginRight: "12px" }}
                      />
                    )}
                  />
                  <div
                    className="d-flex justify-content-center"
                    style={{ paddingTop: "2rem" }}
                  >
                    <Button
                      className="btn btn-primary mt-3 w-75 "
                      onClick={onOtpverify}
                      disabled={loading}
                      variant="contained"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm mr-2"></span>
                      )}
                      Verify OTP
                    </Button>
                  </div>
                </div>
              ) : (
                <div style={{ paddingTop: "2rem" }}>
                  <Typography style={{ paddingBottom: "2rem" }}>
                    Verify Your Phone Number
                  </Typography>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      paddingTop: "2rem",
                    }}
                  >
                    <PhoneInput country={"in"} value={ph} onChange={setPh} />
                  </div>

                  <div
                    className="d-flex justify-content-center"
                    style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
                   
                  >
                    <Tooltip title="OTP SMS WILL BE SENT TO YOUR PHONE NUMBER">
                      <Button
                        className="btn btn-primary mt-3 w-75 "
                        onClick={onSignup}
                        disabled={loading}
                        variant="contained"
                      >
                        {loading && (
                          <span className="spinner-border spinner-border-sm mr-2"></span>
                        )}
                        Send OTP
                        {/* </button> */}
                      </Button>
                    </Tooltip>
                  </div>
                  <div id="recaptcha-container" className="mt-4"></div>
                </div>
              )}
            </div>
          ) : isPasswordChangeSuccessful ? (
            <div className="row position-absolute text-white p-5">
              <p>Password Changed Successfully! Redirecting to home page</p>
            </div>
          ) : (
            <div className="row position-absolute bg-dark text-white p-5">
              <TextField
                disabled
                label="Phone Number"
                value={ph.slice(2)}
                // variant="filled"
                margin="normal"
              />

              <Typography style={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
                Enter New Password
              </Typography>
              <Input
                type={showPassword ? "text" : "password"}
                // type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="New Password"
                className="mb-3"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <div
                className="d-flex justify-content-center"
                style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
              >
                <Button
                  className="btn btn-primary mt-3 w-75 "
                  onClick={handleChangePassword}
                  variant="contained"
                >
                  Change Password
                </Button>
              </div>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default ChangePassword2;
