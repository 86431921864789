import { BASE_URL } from "../App";
import {
  LOGIN_REQUEST,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  ALL_USERS_REQUEST,
  ALL_USERS_SUCCESS,
  ALL_USERS_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  CLEAR_ERRORS,
  LOGOUT_REQUEST,
  GET_ADDRESS_REQUEST,
  GET_ADDRESS_SUCCESS,
  GET_ADDRESS_FAILURE,
  DELETE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_REQUEST,
  DELETE_ADDRESS_FAILURE,
  UPDATE_ADDRESS_REQUEST,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_FAILURE,
  ADD_ADDRESS_REQUEST,
  ADD_ADDRESS_SUCCESS,
  ADD_ADDRESS_FAILURE,
  GET_SLIDER_IMAGES_REQUEST,
  GET_SLIDER_IMAGES_SUCCESS,
  GET_SLIDER_IMAGES_FAIL,
} from "../constants/userConstants";
import axios from "axios";

export const login = (mobile, password) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });

    const requestData = {
      mobile: mobile,
      password: password,
    };

    const response = await axios.post(
      BASE_URL + "/app/middleware/v1/login",
      requestData,
      {
        withCredentials: true,
      }
    );

    if (response?.status === 200) {
      dispatch({ type: LOGIN_SUCCESS, payload: response?.data?.data });
    } else {
      dispatch({ type: LOGIN_FAIL, payload: response?.data?.message });
    }
  } catch (error) {
    // Ensure this captures the response error message
    const errorMessage = error.response?.data?.message || error.message;
    dispatch({ type: LOGIN_FAIL, payload: errorMessage });
  }
};

// Register
// export const register = (userData) => async (dispatch) => {
//   try {
//     dispatch({ type: REGISTER_USER_REQUEST });

//     // const config = { headers: { "Content-Type": "multipart/form-data" } };

//     const { data } = await axios.post(
//       `/app/middleware/v1/register`,
//       userData
//       // config
//     );

//     dispatch({ type: REGISTER_USER_SUCCESS, payload: data?.user });
//   } catch (error) {
//     dispatch({
//       type: REGISTER_USER_FAIL,
//       payload: error.response?.data?.message,
//     });
//   }
// };
export const register = (userData) => async (dispatch) => {
  try {
    dispatch({ type: REGISTER_USER_REQUEST });

    const response = await axios.post(
      BASE_URL + `/app/middleware/v1/register`,
      userData,
      {
        withCredentials: true,
      }
    );

    // Validate response structure before dispatching success action
    if (response?.status === 200) {
      dispatch({ type: REGISTER_USER_SUCCESS, payload: response?.data?.user });
    } else {
      // throw new Error("Invalid response structure from server");
      dispatch({ type: REGISTER_USER_FAIL, payload: response?.data?.message });
    }
  } catch (error) {
    // Fallback to a generic error message if specific message is not available
    // console.log("error", error);
    const errorMessage =
      error.response?.data?.message ||
      "Registration failed due to server error";
    dispatch({ type: REGISTER_USER_FAIL, payload: errorMessage });
  }
};

// Load User
export const loadUser = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_USER_REQUEST });

    const { data } = await axios.get(BASE_URL + `/getuserdata`, {
      withCredentials: true,
    });
    // console.log("data loaduser", data?.userData);

    data?.isAuthenticated
      ? dispatch({ type: LOAD_USER_SUCCESS, payload: data?.userData?.data })
      : dispatch({
          type: LOAD_USER_FAIL,
        });
  } catch (error) {
    dispatch({ type: LOAD_USER_FAIL, payload: error.response?.data?.message });
  }
};

// Logout User
export const logout = () => async (dispatch) => {
  try {
    dispatch({ type: LOGOUT_REQUEST });
    const data = await axios.get(BASE_URL + `/app/api/v1/logout`, {
      withCredentials: true,
    });

    document.cookie =
      "cookieName=userdata; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    // alert("logout", data);

    dispatch({ type: LOGOUT_SUCCESS });
  } catch (error) {
    dispatch({ type: LOGOUT_FAIL, payload: error.response?.data?.message });
  }
};

// Update Profile
export const updateProfile = (userData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PROFILE_REQUEST });

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const { data } = await axios.put(
      BASE_URL + `/api/v1/me/update`,
      userData,
      config,
      {
        withCredentials: true,
      }
    );

    dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: data?.success });
  } catch (error) {
    dispatch({
      type: UPDATE_PROFILE_FAIL,
      payload: error.response?.data?.message,
    });
  }
};

// Update Password
export const updatePassword = (passwords) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PASSWORD_REQUEST });

    const config = { headers: { "Content-Type": "application/json" } };

    const { data } = await axios.put(
      BASE_URL + `/api/v1/password/update`,
      passwords,
      config,
      {
        withCredentials: true,
      }
    );

    dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: data?.success });
  } catch (error) {
    dispatch({
      type: UPDATE_PASSWORD_FAIL,
      payload: error.response?.data?.message,
    });
  }
};

// Forgot Password
export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({ type: FORGOT_PASSWORD_REQUEST });

    const config = { headers: { "Content-Type": "application/json" } };

    const { data } = await axios.post(`/api/v1/password/forgot`, email, config);

    dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: data?.message });
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload: error.response?.data?.message,
    });
  }
};

// Reset Password
export const resetPassword = (token, passwords) => async (dispatch) => {
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST });

    const config = { headers: { "Content-Type": "application/json" } };

    const { data } = await axios.put(
      `/api/v1/password/reset/${token}`,
      passwords,
      config
    );

    dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data?.success });
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload: error.response?.data?.message,
    });
  }
};

// get All Users
export const getAllUsers = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_USERS_REQUEST });
    const { data } = await axios.get(`/api/v1/admin/users`);

    dispatch({ type: ALL_USERS_SUCCESS, payload: data?.users });
  } catch (error) {
    dispatch({ type: ALL_USERS_FAIL, payload: error.response?.data?.message });
  }
};

// get  User Details
export const getUserDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: USER_DETAILS_REQUEST });
    const { data } = await axios.get(`/api/v1/admin/user/${id}`);

    dispatch({ type: USER_DETAILS_SUCCESS, payload: data?.user });
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: error.response?.data?.message,
    });
  }
};

// Delete User
export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_USER_REQUEST });

    const { data } = await axios.delete(`/api/v1/admin/user/${id}`);

    dispatch({ type: DELETE_USER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DELETE_USER_FAIL,
      payload: error.response?.data?.message,
    });
  }
};

// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

export const updateUserRequest = () => ({
  type: UPDATE_USER_REQUEST,
});

export const updateUserSuccess = (userData) => ({
  type: UPDATE_USER_SUCCESS,
  payload: userData,
});

export const updateUserFailure = (error) => ({
  type: UPDATE_USER_FAIL,
  payload: error,
});

// Async action to update the user
export const updateUser = (userData) => {
  return async (dispatch) => {
    dispatch(updateUserRequest());

    try {
      // Make the API request to update the user
      // const response = await axios.post(
      //   BASE_URL + "/app/middleware/v1/updateuser",
      //   userData,
      //   {
      //     withCredentials: true,
      //     credentials: "include", // Correct way to include cookies in Axios
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //   }
      // );

      const response = await axios({
        method: "post", // Changed to PUT for update operation
        url: BASE_URL + "/app/middleware/v1/updateuser",
        withCredentials: true,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        data: userData,
      });

      // Dispatch a success action with the updated user data
      dispatch(updateUserSuccess(response?.data));
    } catch (error) {
      // Dispatch a failure action with the error message
      dispatch(updateUserFailure(error.message));
    }
  };
};

// export const getAddress = () => (dispatch) => {
//   dispatch({ type: GET_ADDRESS_REQUEST });

//   // Make the API request here
//   fetch(
//     BASE_URL + "/app/middleware/v1/address",
//     {},
//     {
//       withCredentials: true, // Include this option in the Axios configuration
//     }
//   )
//     .then((response) => response?.json())
//     .then((data) => {
//       dispatch({ type: GET_ADDRESS_SUCCESS, payload: data });
//     })
//     .catch((error) => {
//       dispatch({ type: GET_ADDRESS_FAILURE, payload: error });
//     });
// };
export const getAddress = () => (dispatch) => {
  dispatch({ type: GET_ADDRESS_REQUEST });

  fetch(BASE_URL + "/app/middleware/v1/address", {
    credentials: "include", // Correct way to include cookies in fetch
  })
    .then((response) => response?.json())
    .then((data) => {
      dispatch({ type: GET_ADDRESS_SUCCESS, payload: data.data });
    })
    .catch((error) => {
      dispatch({ type: GET_ADDRESS_FAILURE, payload: error });
    });
};

export const deleteAddress = (id) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_ADDRESS_REQUEST });
    try {
      // const response = await axios({
      //   method: "post", // Use the HTTP DELETE method
      //   url: BASE_URL + "/app/middleware/v1/deleteaddress",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   data: {
      //     id: id,
      //   },
      // });

      const { response } = await axios({
        method: "post", // Changed to PUT for update operation
        url: BASE_URL + "/app/middleware/v1/deleteaddress",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          id: id,
        },
      });

      // alert(response);

      dispatch({
        type: DELETE_ADDRESS_SUCCESS,
        payload: id,
      });

      // You can also dispatch success or error actions based on the response
    } catch (error) {
      console.error("Delete address error:", error);
      dispatch({
        type: DELETE_ADDRESS_FAILURE,
        payload: error,
      });
      // Dispatch an error action if needed
    }
  };
};

// export const updateAddress = (data) => {
//   return async (dispatch) => {
//     dispatch({ type: UPDATE_ADDRESS_REQUEST });
//     try {
//       const response = await axios({
//         method: "post", // Use the HTTP UPDATE method
//         url: BASE_URL + "/app/middleware/v1/updateaddress",
//         headers: {
//           withCredentials: true,
//           credentials: "include",
//           "Content-Type": "application/json",
//         },
//         data: data,
//       });

//       dispatch({
//         type: UPDATE_ADDRESS_SUCCESS,
//         payload: response?.data,
//       });

//       // You can also dispatch success or error actions based on the response
//     } catch (error) {
//       console.error("Update address error:", error);
//       dispatch({
//         type: UPDATE_ADDRESS_FAILURE,
//         payload: error,
//       });
//       // Dispatch an error action if needed
//     }
//   };
// };
export const updateAddress = (data) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_ADDRESS_REQUEST });
    try {
      const response = await axios({
        method: "post", // Changed to PUT for update operation
        url: BASE_URL + "/app/middleware/v1/updateaddress",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      });

      dispatch({
        type: UPDATE_ADDRESS_SUCCESS,
        payload: response?.data,
      });
    } catch (error) {
      console.error("Update address error:", error.response || error);
      dispatch({
        type: UPDATE_ADDRESS_FAILURE,
        payload: error,
      });
    }
  };
};

// export const addAddress = (data) => {
//   return async (dispatch) => {
//     dispatch({ type: ADD_ADDRESS_REQUEST });
//     try {
//       const response = await axios(
//         {
//           method: "post", // Use the HTTP ADD method
//           url: BASE_URL + "/app/middleware/v1/address",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           data: data,
//         },
//         { withCredentials: true }
//       );

//       dispatch({
//         type: ADD_ADDRESS_SUCCESS,
//         payload: response?.data,
//       });

//       // You can also dispatch success or error actions based on the response
//     } catch (error) {
//       console.error("Update address error:", error);
//       dispatch({
//         type: ADD_ADDRESS_FAILURE,
//         payload: error,
//       });
//       // Dispatch an error action if needed
//     }
//   };
// };

export const addAddress = (data) => {
  return async (dispatch) => {
    dispatch({ type: ADD_ADDRESS_REQUEST });
    try {
      const response = await axios({
        method: "post",
        url: BASE_URL + "/app/middleware/v1/address",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
        withCredentials: true, // Correct placement
      });

      dispatch({
        type: ADD_ADDRESS_SUCCESS,
        payload: response?.data,
      });
    } catch (error) {
      // console.error("Add address error:", error); // Corrected message
      dispatch({
        type: ADD_ADDRESS_FAILURE,
        payload: error,
      });
    }
  };
};

export const getSliderImages = () => async (dispatch) => {
  try {
    dispatch({ type: GET_SLIDER_IMAGES_REQUEST });

    const { data } = await axios.get(
      BASE_URL + "/app/middleware/v1/getSliderImages"
    );

    dispatch({
      type: GET_SLIDER_IMAGES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_SLIDER_IMAGES_FAIL,
      payload:
        error.response && error.response?.data?.message
          ? error.response?.data?.message
          : error.message,
    });
  }
};
