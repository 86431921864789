// import * as React from "react";
// import { useTheme } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
// import IconButton from "@mui/material/IconButton";
// import Typography from "@mui/material/Typography";
// import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
// import PlayArrowIcon from "@mui/icons-material/PlayArrow";
// import SkipNextIcon from "@mui/icons-material/SkipNext";
// import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
// import "./Card.css";
// import { InputNumber } from "antd";
// import { getCart, modifyCart } from "../../actions/cartAction";
// import { useDispatch, useSelector } from "react-redux";
// import { Button } from "@mui/material";
// import { Link } from "react-router-dom";
// import { addToWishlist, loadFavourites } from "../../actions/wishlistAction";
// import { BASE_URL, MEDIA_URL } from "../../App";

// const { Meta } = Card;

// const CartCard = ({itemId, product ,SetDisableCheckout}) => {

//   console.log("product",product,itemId)
//   const dispatch = useDispatch();

//   const { isAuthenticated, user } = useSelector((state) => state.user);
//   const userId = user && user.id;

//   const variantid = product && product.product_variant_id;
//   const qty = product && product.qty;
//   const maxQty = parseInt(product && product.product_variants[0].stock);
//   console.log("maxQty",maxQty)
//   const variant_availability = product?.product_variants[0]?.availability
//   console.log("variant_availability",variant_availability)

//   if(variant_availability==0) {
//     SetDisableCheckout(true)
//   }

//   // Check if the product is out of stock
//   const isOutOfStock = maxQty === 0;

//   const onChange = (value) => {
//     dispatch(modifyCart(userId, variantid, parseInt(value)));
//     dispatch(getCart());
//     dispatch(getCart());
//   };

//   const removeFromCart = () => {
//     const num = 0;
//     dispatch(modifyCart(userId, variantid, "0"));
//     dispatch(getCart());
//     dispatch(getCart());
//   };

//   const addToWishListhandler = () => {
//     dispatch(addToWishlist(userId, product.id));
//     dispatch(loadFavourites());
//   };

//   return (
//     <div>
//       <Card
//         sx={{ display: "flex", width: "100%" }}
//         style={{
//           boxShadow: "unset",
//           width:"100%",
//           height:"auto",
//           border: "5px solid #564c40",
//           borderRadius: "0px",
//         }}
//       >
//         <CardContent sx={{ flex: "1 0 auto" }}>
//           <Typography component="div" variant="h6">
//             <Link
//               to={"/product/" + product.id}
//               style={{ all: "unset", cursor: "pointer" }}
//             >
//               {product.name}
//             </Link>
//           </Typography>

//           <Typography style={{ fontSize: "12px" }}>
//             Book Condition: {product.product_variants[0]?.variant_values}
//           </Typography>

//           <div style={{ marginTop: "1rem" }}>
//            {variant_availability!=0 &&  <InputNumber
//               min={1}
//               max={maxQty}
//               defaultValue={parseInt(product.qty)}
//               onChange={onChange}
//             />}

//             {variant_availability==0 && (
//               <Typography style={{ color: "red", fontSize: "12px" }}>
//                 Out of Stock, Please remove from cart to proceed further
//               </Typography>
//             )}

//             <Button
//               variant="text"
//               onClick={() => removeFromCart()}
//               style={{ marginLeft: "2rem", color: "#686763", fontSize: "12px" }}
//             >
//               Remove from cart
//             </Button>

//             <Button
//               variant="text"
//               onClick={() => addToWishListhandler()}
//               style={{ marginLeft: "2rem", color: "#686763", fontSize: "12px" }}
//             >
//               Add To Wishlist
//             </Button>
//           </div>

//           <div style={{ marginTop: "1rem" }}>
//             <Typography variant="h6" style={{ fontSize: "92%" }}>
//               ₹{product.special_price}
//             </Typography>
//           </div>
//         </CardContent>
//         <Link to={"/product/" + product.id}>
//           <div style={{ height: "auto", width: "12rem" }}>
//             <CardMedia
//               style={{ maxWidth: "150px", height: "auto" }}
//               sx={{ width: "151px", padding: "1rem" }}
//               component="img"
//               image={MEDIA_URL + "/" + product.image}
//               alt={product.name}
//             />
//           </div>
//         </Link>
//       </Card>
//     </div>
//   );
// };

// export default CartCard;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import InputNumber from "antd/lib/input-number";
import { modifyCart, getCart } from "../../actions/cartAction";
import { addToWishlist, loadFavourites } from "../../actions/wishlistAction";
import { MEDIA_URL } from "../../App";

const CartCard = ({ itemId, product, SetDisableCheckout }) => {
  const dispatch = useDispatch();


  // console.log("product",product)

  // const userId = useSelector((state) => state.user?.id);
  
  var variantid = ""

let productObj = {}
  if(product?.id === undefined)
  {
    productObj = product?.product_details[0]
    variantid =  productObj?.variants[0]?.id;
    // console.log("variantid",variantid)

  }
  else{
    productObj = product
  variantid = product?.product_variant_id;

  }


  product = productObj
  console.log("product",product)


  const { isAuthenticated, user } = useSelector((state) => state.user);
  const userId = user?.id;
  const maxQty = parseInt(product?.product_variants && product?.product_variants[0]?.stock);
  const variant_availability =  product?.product_variants && product?.product_variants[0]?.availability;

  if (variant_availability === 0 || variant_availability === '0') {
    SetDisableCheckout(true);
  }

  const onChange = (value) => {
    dispatch(modifyCart(userId, variantid, parseInt(value)));
    dispatch(getCart());
  };

  const removeFromCart = async () => {
    await dispatch(modifyCart(userId, variantid, "0"));
    dispatch(getCart());
  };

  const addToWishListhandler = () => {
    dispatch(addToWishlist(userId, product?.id));
    dispatch(loadFavourites());
    alert("Item added to wishlist")
  };



  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        boxShadow: "unset",
        border: "5px solid #564c40",
        m: 1,
        borderRadius: "0px",
      }}
    >
      <Grid container>
        {/* Product Details */}
        <Grid item xs={4} sm={8}>
          <CardContent>
            <Typography variant="h6">
              <Link
                to={`/product/${product?.id}`}
                style={{ textDecoration: "none", color: "inherit",  fontSize: "clamp(0.2rem, 0.3043rem + 3.4783vw, 1rem)", }} 
              >
                {product?.name}
              </Link>
            </Typography>
            <Typography variant="body2" color="text.secondary" style={{paddingTop:"1rem", fontSize: "clamp(0.2rem, 0.3043rem + 3.4783vw, 0.7rem)"}}>
            Variant: {product?.product_variants && product?.product_variants[0]?.variant_values}
            </Typography>
            
            <div style={{paddingTop:"0.5rem"}}>
            {variant_availability !== 0 && (
              <InputNumber
                min={1}
                max={maxQty}
                defaultValue={parseInt(product?.qty)}
                onChange={onChange}
              />
            )}
            {variant_availability === 0 && (
              <Typography color="error">
                Out of Stock, Please remove from cart to proceed further
              </Typography>
            )}
            </div>
            <Typography variant="h6" component="div" style={{paddingTop:"1rem",fontSize: "clamp(0.2rem, 0.3043rem + 3.4783vw, 0.8rem)"}}>
              ₹{product?.special_price}
            </Typography>
            <Grid
              item
              xs={4}
              sm={8}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                p: 0,
              }}
            >
              <Button onClick={removeFromCart} sx={{ fontSize: "clamp(0.2rem, 0.3043rem + 3.4783vw, 0.5rem)" }}>
                Remove from cart
              </Button>
              <Button onClick={addToWishListhandler} sx={{ fontSize: "clamp(0.2rem, 0.3043rem + 3.4783vw, 0.5rem)" }}>
                Add To Wishlist
              </Button>
            </Grid>
          </CardContent>
        </Grid>
        <Grid
          item
          xs={8}
          sm={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link to={`/product/${product?.id}`}>
            <CardMedia
              component="img"
              sx={{ width: 151, padding: "1rem" }}
              image={`${MEDIA_URL}/${product?.image}`}
              alt={product?.name}
            />
          </Link>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CartCard;
