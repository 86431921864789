import React from "react";
import { Card, CardContent, CardMedia, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { MEDIA_URL } from "../../App";

const CheckoutProductCard = ({ product }) => {
  return (
    <Box sx={{ margin: "auto", width: "15rem" }}>
      <Card sx={{ display: "flex", flexDirection: "column", boxShadow: "unset", border: 5, borderColor: "#564c40", borderRadius: 0, height: "20rem" }}>
        <Link to={`/product/${product.id}`} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'column', height: '100%' }}>
          <CardMedia
            component="img"
            sx={{ width: "100%", height: "60%", objectFit: 'contain', padding: "1rem" }}
            image={MEDIA_URL+`/${product.image}`}
            alt={product.name}
          />
          <CardContent sx={{ flexGrow: 1, overflow: 'hidden' }}>
            <Typography variant="h5" noWrap gutterBottom>
              {product.name}
            </Typography>
            {/* {product.variant_values && (
              <Typography variant="subtitle1" color="text.secondary" noWrap>
                Variant: {product.variant_values}
              </Typography>
            )} */}
            <Typography variant="h6" sx={{ marginTop: 'auto' }}>₹{product.special_price}</Typography>
          </CardContent>
        </Link>
      </Card>
    </Box>
  );
};

export default CheckoutProductCard;
