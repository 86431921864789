import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';

const CategoriesComponent = ({ categories }) => {
  const navigate = useNavigate();
  const location = useLocation();


  const handleCategoryClick = (id) => {
    // navigate(`?category_id=${id}`);
    const searchParams = new URLSearchParams(location.search);
    // Append the new category_id param
    searchParams.set('category_id', id);
    // Navigate without replacing the current search params
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const renderCategories = (categories) => {
    return categories.map((category) => (
      <React.Fragment key={category.id}>
        <ListItem  onClick={() => handleCategoryClick(category.id)}>
          {/* <ListItemIcon> */}
          {category.image ? category && <img src={category.image} style={{width:"20%",marginRight:"1rem"}}/>: <ImageIcon /> }
            
          {/* </ListItemIcon> */}
          <ListItemText primary={category.name} />
        </ListItem>
        {category.children && category.children.length > 0 && (
          <List component="div" disablePadding>
            {renderCategories(category.children)}
          </List>
        )}
      </React.Fragment>
    ));
  };

  return (
    <List>
      {renderCategories(categories)}
    </List>
  );
};

export default CategoriesComponent;
