import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Container, Typography, TextField, Alert } from "@mui/material";
import { updateAddress } from "../../actions/userAction";
import { Check } from "@mui/icons-material";

const EditAddressForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const addresses = useSelector(
    (state) =>
      state?.address?.address?.data
  );

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const addressId = searchParams.get("id");

  useEffect(() => {
    const addressToEdit = addresses?.find((address) => address.id === addressId);
    if (addressToEdit) {
      setFormData(addressToEdit);
    }
  }, [addressId, addresses]);

  const [success,setSuccess]=useState(false)
  const [formData, setFormData] = useState({
    type: "Home", // default type
    name: "",
    mobile: "",
    alternate_mobile: "",
    address: "",
    landmark: "",
    area_id: "",
    city_id: "",
    other_city: "",
    other_areas: "",
    pincode: "",
    pincode_name: "",
    state: "",
    country: "India", // default country
    is_default: "0", // default to '0' (not the default address)
  });

  const handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   console.log("formData",formData)
  //   dispatch(updateAddress(formData))

  // };


  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   // Dispatch the addAddress action and handle the promise
  //   dispatch(updateAddress(formData))
  //     .then(() => {
  //       setSuccess(true);
  //       navigate(-1); 
  //     })
  //     .catch((error) => {
  //       // Handle any errors here
  //       console.error("Error adding address:", error);
  //     });
  // };
  const timeout = 5;
  const handleSubmit = (event) => {
    event.preventDefault();
    
    dispatch(updateAddress(formData))
      .then(() => {
        setSuccess(true); // Setting success state
        // Set a timer for 5 seconds before navigating back
        setTimeout(() => {
          navigate(-1); // Navigate back after 5 seconds
        }, timeout*1000);
      })
      .catch((error) => {
        console.error("Error updating address:", error);
      });
  };
  

  return (
    <Container>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <Typography variant="h5" gutterBottom>
          Edit Address
        </Typography>


        {/* Use the standard TextField from MUI */}
        <TextField
          variant="standard"
          margin="normal"
          fullWidth
          id="name"
          label="Name"
          name="name"
          value={formData.name || ''}
          onChange={handleInputChange}
        />

        <TextField
          variant="standard"
          margin="normal"
          fullWidth
          id="mobile"
          label="Mobile"
          name="mobile"
          value={formData.mobile || ''}
          onChange={handleInputChange}
        />
        <TextField
          variant="standard"
          margin="normal"
          fullWidth
          id="alternate_mobile"
          label="Alternate Mobile"
          name="alternate_mobile"
          value={formData.alternate_mobile || ''}
          onChange={handleInputChange}
        />
        <TextField
          variant="standard"
          margin="normal"
          fullWidth
          id="address"
          label="Address"
          name="address"
          value={formData.address || ''}
          onChange={handleInputChange}
        />
        <TextField
          variant="standard"
          margin="normal"
          fullWidth
          id="landmark"
          label="Landmark"
          name="landmark"
          value={formData.landmark || ''}
          onChange={handleInputChange}
        />
        {/* ... Add other fields in the same manner ... */}
        <TextField
          variant="standard"
          margin="normal"
          fullWidth
          id="other_city"
          label="Other City"
          name="other_city"
          value={formData.other_city || ''}
          onChange={handleInputChange}
        />
        <TextField
          variant="standard"
          margin="normal"
          fullWidth
          id="other_areas"
          label="Other Areas"
          name="other_areas"
          value={formData.other_areas || ''}
          onChange={handleInputChange}
        />
        <TextField
          variant="standard"
          margin="normal"
          fullWidth
          id="pincode"
          label="Pincode"
          name="pincode"
          value={formData.pincode || ''}
          onChange={handleInputChange}
        />
 
        <TextField
          variant="standard"
          margin="normal"
          fullWidth
          id="state"
          label="State"
          name="state"
          value={formData.state || ''}
          onChange={handleInputChange}
        />
        <TextField
          variant="standard"
          margin="normal"
          fullWidth
          id="country"
          label="Country"
          name="country"
          value={formData.country || ''}
          onChange={handleInputChange}
        />
        {/* ... add other fields ... */}
        
        {/* <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Update Address
        </Button> */}
              {success && <Alert icon={<Check fontSize="inherit" />} severity="success">
  {`Address updated successfully, redirecting you back... `}
</Alert>}
        <div style={{display:"flex",flexDirection:"row"}}>
  
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          style={{margin:"1rem"}}
        >
          Update Address
        </Button>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          style={{margin:"1rem"}}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>


        </div>
      </Box>
    </Container>
  );
};

export default EditAddressForm;
