import React, { useState } from "react";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { logout } from "../../actions/userAction";
import { Link } from "react-router-dom";
// import "./header.css";

const UserButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();

  const open = Boolean(anchorEl);

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { isAuthenticated } = useSelector((state) => state.user);

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{ color: "unset" }}
      >
        <AccountCircleIcon style={{color:"#1b1303"}}/>

        <Typography
          sx={{ display: { xs: "none", sm: "block" } }}
          style={{ paddingLeft: "15px", color: "#1b1303",fontSize: "clamp(0.2rem, 0.3043rem + 3.4783vw, 0.9rem)",textTransform:"capitalize"}}
        >
          {isAuthenticated ? "Dashboard" : "Login/Register"}
        </Typography>
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {isAuthenticated ? (
          <>
            <Link to="/profile" style={{ all: "unset" }}>
              <MenuItem onClick={handleClose}>Profile</MenuItem>
            </Link>

            <Link to="/orders" style={{ all: "unset" }}> <MenuItem onClick={handleClose}>My Orders</MenuItem></Link>
            <MenuItem onClick={handleLogout}>
              <Button
                variant="text"
                onClick={handleLogout}
                style={{ padding: "0rem" }}
                href="/"
              >
                Logout
              </Button>
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem onClick={handleClose}>
              <Link to="/login">Login</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to="/signup">Register</Link>
            </MenuItem>
          </>
        )}
      </Menu>
    </div>
  );
};

export default UserButton;
