import React, { useState } from 'react';
import { Card, CardContent, Typography, Avatar, Rating, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  },
});

const ReviewCard = ({ review }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleShowMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card sx={{ maxWidth: 600, mb: 2 }}>
      <CardContent>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <Avatar alt={review.user_name} src={review.user_profile} sx={{ marginRight: '10px' }} />
          <div>
            <Typography variant="subtitle2">{review.user_name}</Typography>
            <Typography variant="body2" color="text.secondary">
              {review.data_added.split(' ')[0]} {/* Extracting only the date part */}
            </Typography>
          </div>
        </div>
        <StyledRating name="read-only" value={parseInt(review.rating)} readOnly />
        <Typography variant="body1" gutterBottom>
          {isExpanded ? review.comment : `${review.comment.substring(0, 100)}...`}
        </Typography>
        {review.comment.length > 100 && (
          <Button color="primary" onClick={handleShowMore}>
            {isExpanded ? 'Show less' : 'Show more'}
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default ReviewCard;
