import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCCbG2M7OjIN8BtJj_rKUrhUaXJK42FXMA",
  authDomain: "kitablovers-a5efd.firebaseapp.com",
  projectId: "kitablovers-a5efd",
  storageBucket: "kitablovers-a5efd.appspot.com",
  messagingSenderId: "10571716597",
  appId: "1:10571716597:web:484e503c5cf01af92532bc",
  measurementId: "G-SDYZBBCYYL",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
export { auth, provider, firebaseConfig };
