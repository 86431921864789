import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchOrders } from '../../actions/orderAction'

import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  NativeSelect,
  Rating,
  Typography,
  badgeClasses,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { loadUser, updateUser } from '../../actions/userAction';


const OrderConfirmation = () => {

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchOrders());
        dispatch(updateUser());
        dispatch(loadUser());
        window.scrollTo(0, 0);

    }, [dispatch])

    const orders = useSelector((state) => state.orders?.orders?.data);

    const findOrderById = (orderId, orders) => {
      return orders && orders.find(order => order?.id === orderId);
    };
    

    const {id} = useParams();
    const order = findOrderById(id, orders);

    
    // console.log(order)
    
  return (
    <>
    <div>
<Container style={{minHeight:"50rem"}}>
  <div>
  <Typography style={{paddingTop:"5rem"}} variant='h2'>Thank you for your Order</Typography>
  
  <Typography>We are getting started on your order right away, and you will receive an order confirmation email shortly. In the meantime, explore the latest releases and Bestsellers, just head over to Trending Section</Typography>
  </div>
</Container>
    </div>
    </>
  )
}

export default OrderConfirmation