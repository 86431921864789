import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Button } from "@mui/material";
import { BASE_URL } from "../../App";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ResponsiveDialog from "../layout/Dialog";

const CheckoutButton = ({
  productDetails,
  quantities,
  userData,
  wallet_flg,
  wallet_balance,
  buttontext,
  amounttopay,
  selectedAddress,
}) => {

  console.log("buttontext",buttontext)
  const [checkoutButtonText, SetCheckoutButtonText] = useState(buttontext);
  console.log("wallet_flg,wallet_balance", wallet_flg, wallet_balance);
  const isCheckoutDisabled = !Object.values(quantities).some(
    (quantity) => quantity > 0
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Payment Specific States
  const [payment_id, setPayment_id] = useState(""); // Loading state
  const [PaymentStatus, SetPaymentStatus] = useState("");
  const [PaymentToken, SetPaymentToken] = useState("");
  const [merchantTransactionId, SetMerchantTransactionId] = useState("");
  const [transactionId, SettransactionId] = useState("");
  const [merchantId, SetMerchantId] = useState("");
  const [token, setToken] = useState("");
  const [loadingPayment, setLoadingPayment] = useState(false);

  // OrderObject
  const [createOrderObj, SetCreateOrderObj] = useState("");
  const [orderObj, SetOrderObj] = useState("");

  const [orderResponse, setOrderResponse] = useState(null);

  const [processingOrder, SetProcessingOrder] = useState(false);

  const processingOrderRef = useRef(false);

  const WaitingForPaymentRef = useRef(false);

  const merchantIdRef = useRef("");
  const merchantTransactionIdRef = useRef("");
  const originalTransactionIdRef = useRef("");
  const amountRef = useRef("");
  
  const [open, setOpen] = React.useState(false);


  const { address_id, user_id, mobile, payment_method } = userData;

  // Construct product_variant_id and quantity arrays from `quantities`
  // const product_variant_id = Object.keys(quantities);
  // const quantity = Object.values(quantities);
  // Assuming `quantities` is an object where the key is the variant ID and the value is the quantity
  const product_variant_id = [];
  const quantity = [];

  Object.entries(quantities).forEach(([variantId, qty]) => {
    if (qty > 0) {
      product_variant_id.push(variantId);
      quantity.push(qty);
    }
  });



  // Calculate totals (simplified, you might need a more complex calculation based on your business logic)
  const total = productDetails.data.data.reduce((acc, product) => {
    const productTotal = product.variants.reduce((variantAcc, variant) => {
      if (quantities[variant.id]) {
        // Assuming `price` needs to be parsed as integer. Adjust calculation as needed.
        return (
          variantAcc + parseInt(variant.special_price, 10) * quantities[variant.id]
        );
      }
      return variantAcc;
    }, 0);
    return acc + productTotal;
  }, 0);

  // Delivery charge, tax_amount, and tax_percentage might be static or calculated
  const delivery_charge = 0;
  const tax_amount = 0;
  const tax_percentage = 0;
  const final_total = total + delivery_charge + tax_amount; // Simplified

  // Construct the payload
  const payload = {
    address_id,
    user_id,
    mobile,
    product_variant_id,
    quantity,
    total,
    delivery_charge,
    tax_amount,
    tax_percentage,
    final_total,
    payment_method,
    delivery_time: "Today - Evening (4:00pm to 7:00pm)", // Example, adjust as needed
    is_wallet_used: wallet_flg,
    wallet_balance_used: wallet_balance,
  };

  console.log("payload", payload);

  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  useEffect(() => {
    if (amounttopay == 0) {
      SetCheckoutButtonText("Place Order");
    } else {
      SetCheckoutButtonText("Make Payment and Place Order");
    }
  }, [amounttopay]);


  const processOrder = async () => {
    if (
      (PaymentStatus === "Completed" || PaymentStatus === "PAYMENT_SUCCESS") &&
      amounttopay !== 0 &&
      !processingOrderRef.current
    ) {
      processingOrderRef.current = true;
      SetProcessingOrder(true);
      try {
        // throw new Error('Input must be a number');
        const createOrder = await axios.post(
          BASE_URL + "/app/middleware/v1/createorder",
          payload
        );

        const createScanner = await axios.post(
          BASE_URL + "/app/middleware/v1/createscanner",
          {
            "orderid":createOrder?.data?.order_id.toString(),
            "status":"success"
          }
        );

        if (createOrder.data.error) {
          // console.log("Some error occurred", createOrder.data);
          const refundresponse = await axios.post(
            BASE_URL + "/app/payment/v1/create-refund",
            {
       
                merchantUserId: userData.user_id,
                originalTransactionId: originalTransactionIdRef.current,
                merchantTransactionId: merchantTransactionIdRef.current,
                amount: amounttopay,
                // callbackUrl: "https://webhook.site/callback-url"
            
            }
          );

          navigate(`/orderconfirmation/error`);
          // navigate(`/orderconfirmation/error?${refundresponse?.refund?.id}?refundamount=${refundresponse?.refund?.refund_amount}?payment_id=${refundresponse.refund.payment_id}`);

          return; // Early return to avoid navigating to the success page
        }

        navigate(
          `/orderconfirmation/${createOrder?.data?.order_item_data[0]?.order_id}`
        );
      } catch (error) {
        console.error("Error in processOrder:", error);
        
        // const refundresponse = await axios.post(
        //   BASE_URL + "/app/payment/v1/create-refund",
        //   {
        //     type: "TNR",
        //     transaction_id: "transaction_id", // Ensure this is the correct transaction ID
        //     error_message: error.message,
        //     refund_amount: createOrderObj.final_total,
        //     payment_id: payment_id, // Make sure payment_id is defined and correct
        //     access_token: PaymentToken, // Ensure PaymentToken is the correct token
        //   }
        // );
        const refundresponse = await axios.post(
          BASE_URL + "/app/payment/v1/phonepe-refund",
          {
     
              merchantUserId: userData.user_id,
              originalTransactionId: originalTransactionIdRef.current,
              merchantTransactionId: merchantTransactionIdRef.current,
              amount: amounttopay,
              // callbackUrl: "https://webhook.site/callback-url"
          
          }
        );

        navigate(`/orderconfirmation/error`);
      }
    }
  };


  useEffect(() => {
    const checkPaymentStatus = async () => {
      if (merchantTransactionId) {
        try {
          const response = await axios.post(
            BASE_URL + `/app/payment/v1/status`,
            {
              transactionId:merchantTransactionId,
              merchantId:merchantId
          }
          
          );

          SetPaymentStatus(response?.data?.message?.code);
          console.log("response?.data?.code",response?.data?.message?.code)

          SettransactionId(
            response?.data?.message?.data?.transactionId
          );

          originalTransactionIdRef.current = response?.data?.message?.data?.transactionId

          // console.log("status response", response.data);
          const status = response?.data?.status;
        } catch (error) {
          console.error("Error checking payment status:", error);
        }

        processOrder();
      }
    };

    var timeout = 2000;
    if (PaymentStatus === "PAYMENT_SUCCESS") {
      timeout = 100000;
    }
    const intervalId = setInterval(checkPaymentStatus, timeout);
    checkPaymentStatus();

    return () => clearInterval(intervalId);
  }, [PaymentStatus, merchantTransactionId]);

  const handlePayment = async () => {
    alert(
      "This is a self pickup product, you need to collect it from event. It will not be delivered"
    );
    WaitingForPaymentRef.current = true;
    SetCheckoutButtonText("Processing...");

    if (amounttopay === 0) {
      SetProcessingOrder(true);
      const createOrder = await axios.post(
        BASE_URL + "/app/middleware/v1/createorder",
        payload
      );

      const createScanner = await axios.post(
        BASE_URL + "/app/middleware/v1/createscanner",
        {
          "orderid":createOrder?.data?.order_id.toString(),
          "status":"success"
        }
      );
      console.log("Some error occ", createOrder.error);

      // const createScanner = await axios.post(
      //   BASE_URL + "/app/middleware/v1/createscanner",
      //   {
      //     "orderid":createOrder?.data?.order_id.toString(),
      //     "status":"success"
      //   }
      // );
      // console.log("Some error occ", createOrder.error);
      SetPaymentStatus("PAYMENT_SUCCESS");

     

      if (createOrder.data.error) {
        // console.log("Some error occ", createOrder.data.error);
        navigate(`/orderconfirmation/error`);
      } else {
        navigate(
          `/orderconfirmation/${createOrder?.data?.order_item_data[0]?.order_id}`
        );
      }
    }

    try {
      if (amounttopay !== 0) {
        // const token = await getToken(); // Wait for getToken to complete

        axios
          .post(BASE_URL + "/app/payment/v1/payment", {
            name: userData.user_id,
            amount: amounttopay,
            number: selectedAddress?.mobile,
            MUID: "MUID" + Date.now(),
            transactionId: "T" + Date.now(),
          })
          .then((res) => {
            SetMerchantTransactionId(
              res.data.message.data.merchantTransactionId
            );
            SetMerchantId(res.data.message.data.merchantId);
            merchantIdRef.current = res.data.message.data.merchantId;
            merchantTransactionIdRef.current =
              res.data.message.data.merchantTransactionId;

            setTimeout(() => {}, 1500);
            console.log(
              "res.data.data.instrumentResponse.redirectInfo.url",
              res.data.message.data.instrumentResponse.redirectInfo.url
            );
            const url =
              res?.data?.message?.data?.instrumentResponse?.redirectInfo?.url;

              
            if (url) {
              window.open(url, "_blank");
            } else {
              // Handle the missing URL scenario, maybe set an error state or log an error
            }
          })
          .catch((error) => {
            console.error(error);
          });

          

        // SetPaymentToken(token);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCheckout = async () => {
    // Assuming `userData` contains user-specific information like address_id, user_id, etc.
    const { address_id, user_id, mobile, payment_method } = userData;

    // Construct product_variant_id and quantity arrays from `quantities`
    // const product_variant_id = Object.keys(quantities);
    // const quantity = Object.values(quantities);
    // Assuming `quantities` is an object where the key is the variant ID and the value is the quantity
    const product_variant_id = [];
    const quantity = [];

    Object.entries(quantities).forEach(([variantId, qty]) => {
      if (qty > 0) {
        product_variant_id.push(variantId);
        quantity.push(qty);
      }
    });

    alert(
      "This is a self pickup product, you need to collect it from event. It will not be delivered"
    );

    // Calculate totals (simplified, you might need a more complex calculation based on your business logic)
    const total = productDetails.data.data.reduce((acc, product) => {
      const productTotal = product.variants.reduce((variantAcc, variant) => {
        if (quantities[variant.id]) {
          // Assuming `price` needs to be parsed as integer. Adjust calculation as needed.
          return (
            variantAcc + parseInt(variant.price, 10) * quantities[variant.id]
          );
        }
        return variantAcc;
      }, 0);
      return acc + productTotal;
    }, 0);

    // Delivery charge, tax_amount, and tax_percentage might be static or calculated
    const delivery_charge = 200;
    const tax_amount = 0;
    const tax_percentage = 0;
    const final_total = total + delivery_charge + tax_amount; // Simplified

    // Construct the payload
    const payload = {
      address_id,
      user_id,
      mobile,
      product_variant_id,
      quantity,
      total,
      delivery_charge,
      tax_amount,
      tax_percentage,
      final_total,
      payment_method,
      delivery_time: "Today - Evening (4:00pm to 7:00pm)", // Example, adjust as needed
      is_wallet_used: wallet_flg,
      wallet_balance_used: wallet_balance,
    };

    console.log("payload", payload);
    // try {
    //   const createOrder = await axios.post(BASE_URL+'/app/middleware/v1/createorder', payload, {
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //   });

    //   const createScanner = await axios.post(
    //     BASE_URL + "/app/middleware/v1/createscanner",
    //     {
    //       "orderid":createOrder?.data?.order_id.toString(),
    //       "status":"success"
    //     }
    //   );
    //   // Handle response
    //   setOrderResponse(createOrder.data);
    //   console.log('Order successful:', createOrder.data);
    // } catch (error) {
    //   console.error('Order creation failed:', error);
    //   // Handle error
    // }
  };


  return (
    <>
     {processingOrderRef.current && (
        <ResponsiveDialog
          handleClose={handleClose}
          handleClickOpen={handleClickOpen}
          open={true}
          message="Payment Recieved, Please do not close or refresh this page"
          title="Processing Order"
        />
      )}

      {(
PaymentStatus === "BAD_REQUEST" || 
PaymentStatus === "AUTHORIZATION_FAILED" || 
PaymentStatus === "INTERNAL_SERVER_ERROR" || 
PaymentStatus === "TRANSACTION_NOT_FOUND" || 
PaymentStatus === "PAYMENT_ERROR" || 
PaymentStatus === "PAYMENT_PENDING" || 
PaymentStatus === "PAYMENT_DECLINED" || 
PaymentStatus === "TIMED_OUT" 
      ) && (
        <ResponsiveDialog
          handleClose={handleClose}
          handleClickOpen={handleClickOpen}
          open={true}
          message="Please do not close or refresh this page"
          title="Waiting for Payment"
        />
      )}
      
    <Button
      variant="contained"
      // disabled={isCheckoutDisabled}
      disabled={!selectedAddress?.id || WaitingForPaymentRef.current || isCheckoutDisabled}
      // onClick={handleCheckout}
      onClick={() => handlePayment()}

    >
      {checkoutButtonText}
    </Button>
    </>
  );
};

export default CheckoutButton;
