import React, { useState, useEffect } from "react";
import axios from "axios"; // Make sure to install axios
import "./SurpriseBoxPage.css"; // Create and style your CSS file accordingly
import { Container, Typography } from "@mui/material";

const ProductCard = ({ product }) => {
  return (
    <div className="product-card">
      <img
        style={{
          cursor: product.id === "" ? "" : "pointer",
          borderRadius: "2rem",
        }}
        onClick={() => {
          if (product.id) {
            // Checks if product.link is not an empty string
            window.open("/product/" + product.id, "_blank"); // Opens the link in a new tab
          }
        }}
        src={product.image_sm}
        alt={product.name}
      />
      <h3>{product.name}</h3>
      <p>{product.short_description}</p>
      {/* You can add more details here */}
    </div>
  );
};

const SurpriseBoxPage = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.post(
          "https://webbackend.kitablovers.com/app/middleware/v1/getProducts?category_id=161&limit=100&offset=0",
          {
            params: {
              category_id: 161,
              limit: 100,
              offset: 0,
            },
          }
        );
        if (response.data && !response.data.error) {
          setProducts(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div style={{ backgroundColor: "#eeece5" }}>
      <Container maxWidth="xl">
        <div className="products-page">
          <div style={{padding:"1rem 0rem"}}>
<Typography variant="h3" className="heading">Surprise Boxes</Typography>
          </div>
          <div className="products-grid" style={{ padding: "2rem" }}>
            {products.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SurpriseBoxPage;
