import { Container } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchOrders } from '../../actions/orderAction'
import OrderCard from './OrderCard'

const Orders = () => {

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchOrders())
  }, [dispatch])
  
  const ordersObj = useSelector((state) => state.orders?.orders?.data);
  // console.log("ordersObj",ordersObj)

  return (
    <>
    <Container style={{minHeight:"60rem", padding:"2rem"}}>
    {ordersObj && ordersObj.map((order) =>(
      
      // console.log(order)
      <OrderCard order={order}/>)
    )
    }
      


    </Container>
    </>
  )
}

export default Orders