import { Button, Typography } from "@mui/material";
import React from "react";

// Helper function to format date
const formatDate = (dateString) => {
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const date = new Date(dateString);
  return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
};

const Eventcard = ({ event, past }) => {
  const isPreOrderClosed = (fromDate) => {
    const startDate = new Date(fromDate);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset hours to start of the day for comparison

    // Compare if the start date is today or has passed
    return startDate < today || startDate.toDateString() === today.toDateString();
  };

  return (
    <>
      <div className="event-card">
        <div className="event-location">
          <div style={{ backgroundColor: "#e6ccb2" }} className="event-header">
            <Typography variant="h4">{event.title}</Typography>
          </div>

          <div className="event-content">
            <Typography>{event.sub_title}</Typography>
            {!past && (
              <Typography style={{ color: "#c1121f", fontWeight: "bold" }}>
                From {formatDate(event.from_date)} to {formatDate(event.to_date)}
              </Typography>
            )}
            {past && (
              <Typography>
                From {formatDate(event.from_date)} to {formatDate(event.to_date)}
              </Typography>
            )}
            <Typography>Event Mode: Offline</Typography>

            <div className="event-actions">
              {!past && !isPreOrderClosed(event.from_date) && (
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#ffb705",
                    color: "#2b2d42",
                    marginLeft: "2rem",
                  }}
                  href={`/preorder/${event.id}`}
                >
                  Pre Order
                </Button>
              )}

              {isPreOrderClosed(event.from_date) && (
                <div>Pre Order Closed</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Eventcard;
