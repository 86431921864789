import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Container,
  Typography,
  TextField,
  Alert,
  Snackbar,
} from "@mui/material";
import { addAddress, updateAddress } from "../../actions/userAction";

const AddAddressForm2 = () => {
  const dispatch = useDispatch();
  const addresses = useSelector(
    (state) =>
      state.address && state.address.address && state.address.address.data
  );

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const addressId = searchParams.get("id");

  useEffect(() => {
    const addressToEdit = addresses?.find(
      (address) => address.id === addressId
    );
    if (addressToEdit) {
      setFormData(addressToEdit);
    }
  }, [addressId, addresses]);

  const [formData, setFormData] = useState({
    type: "Home", // default type
    name: "",
    mobile: "",
    alternate_mobile: "",
    address: "",
    landmark: "",
    area_id: "",
    city_id: "",
    other_city: "",
    other_areas: "",
    pincode: "",
    pincode_name: "",
    state: "",
    country: "India", // default country
    is_default: "0", // default to '0' (not the default address)
  });

  const handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   // console.log("formData",formData)
  //   dispatch(addAddress(formData))

  // };
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false); // To control the Snackbar visibility
  const [snackbarMessage, setSnackbarMessage] = useState(""); // Message to display in the Snackbar

  const navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    const mandatoryFields = [
      "name",
      "mobile",
      "address",
      "landmark",
      "other_city",
      "other_areas",
      "pincode",
      "state",
      "country",
    ];

    const isAllFieldsFilled = mandatoryFields.every(
      (field) => formData[field].trim() !== ""
    );

    if (!isAllFieldsFilled) {
      setSnackbarMessage("Please fill in all required fields.");
      setShowSnackbar(true);
      setShowAlert(true);
      setAlertMessage("Please fill in all required fields.");
      return; // Prevents the form from being submitted
    }
else{
    // If all fields are filled, proceed with submitting the form
    setShowAlert(false);

}



    // Dispatch the addAddress action and handle the promise
    dispatch(addAddress(formData))
      .then(() => {
        alert("Address added successfully");
        navigate(-1); // Navigates to the previous page
      })
      .catch((error) => {
        // Handle any errors here
        console.error("Error adding address:", error);
      });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
  };
  return (
    <Container style={{paddingTop:"2rem",paddingBottom:"2rem"}}>
      {/* {showAlert && (
        <Alert severity="error" style={{ marginBottom: "1rem" }}>
          {alertMessage}
        </Alert>
      )} */}
        <Snackbar open={showSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <Typography variant="h5" gutterBottom>
          Add Address
        </Typography>

        {/* Use the standard TextField from MUI */}
        <TextField
          variant="standard"
          margin="normal"
          fullWidth
          id="name"
          label="Name"
          name="name"
          required
          value={formData.name || ""}
          onChange={handleInputChange}
        />

        <TextField
        required
          variant="standard"
          margin="normal"
          fullWidth
          id="mobile"
          label="Mobile"
          name="mobile"
          value={formData.mobile || ""}
          onChange={handleInputChange}
        />
        <TextField
        required
          variant="standard"
          margin="normal"
          fullWidth
          id="alternate_mobile"
          label="Alternate Mobile"
          name="alternate_mobile"
          value={formData.alternate_mobile || ""}
          onChange={handleInputChange}
        />
        <TextField
        required
          variant="standard"
          margin="normal"
          fullWidth
          id="address"
          label="Address"
          name="address"
          value={formData.address || ""}
          onChange={handleInputChange}
        />
        <TextField
        required
          variant="standard"
          margin="normal"
          fullWidth
          id="landmark"
          label="Landmark"
          name="landmark"
          value={formData.landmark || ""}
          onChange={handleInputChange}
        />
        {/* ... Add other fields in the same manner ... */}
        <TextField
        required
          variant="standard"
          margin="normal"
          fullWidth
          id="other_city"
          label="City"
          name="other_city"
          value={formData.other_city || ""}
          onChange={handleInputChange}
        />
        <TextField
        required
          variant="standard"
          margin="normal"
          fullWidth
          id="other_areas"
          label="Areas"
          name="other_areas"
          value={formData.other_areas || ""}
          onChange={handleInputChange}
        />
        <TextField
        required
          variant="standard"
          margin="normal"
          fullWidth
          id="pincode"
          label="Pincode"
          name="pincode"
          value={formData.pincode || ""}
          onChange={handleInputChange}
        />

        <TextField
        required
          variant="standard"
          margin="normal"
          fullWidth
          id="state"
          label="State"
          name="state"
          value={formData.state || ""}
          onChange={handleInputChange}
        />
        <TextField
        disabled
          variant="standard"
          margin="normal"
          fullWidth
          id="country"
          label="Country"
          name="country"
          value={formData.country || ""}
          onChange={handleInputChange}
        />
        {/* ... add other fields ... */}

        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            style={{ margin: "1rem" }}
          >
            Add Address
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            style={{ margin: "1rem" }}
            // href="/profile"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
      </Box>
    </Container>
  );
};

export default AddAddressForm2;
