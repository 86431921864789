import { Box, Container, LinearProgress, Skeleton, Typography } from '@mui/material'
import React from 'react'

const LinearLoader = () => {
  return (
    <>
     <LinearProgress />
     <Container style={{minHeight:"55rem",display:"flex",flexDirection:"column",justifyContent:"center"}}>
     
      <Skeleton width={"100%"} height={600} animation="wave"/>
      <Skeleton width={"100%"} height={300} animation="wave" />
      <Skeleton width={"100%"} height={200} animation="wave" />
    
     </Container>
    </>
  )
}

export default LinearLoader