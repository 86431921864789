export const NEW_WISHLIST_PRODUCT_REQUEST = "NEW_WISHLIST_PRODUCT_REQUEST";
export const NEW_WISHLIST_PRODUCT_SUCCESS = "NEW_WISHLIST_PRODUCT_SUCCESS";
export const NEW_WISHLIST_PRODUCT_RESET = "NEW_WISHLIST_PRODUCT_RESET";
export const NEW_WISHLIST_PRODUCT_FAIL = "NEW__WISHLIST_PRODUCT_FAIL";

export const WISHLIST_PRODUCT_REQUEST = "WISHLIST_PRODUCT_REQUEST";
export const WISHLIST_PRODUCT_SUCCESS = "WISHLIST_PRODUCT_SUCCESS";
export const WISHLIST_PRODUCT_RESET = "WISHLIST_PRODUCT_RESET";
export const WISHLIST_PRODUCT_FAIL = "WISHLIST_PRODUCT_FAIL";

export const DELETE_WISHLIST_PRODUCT_REQUEST =
  "DELETE_WISHLIST_PRODUCT_REQUEST";
export const DELETE_WISHLIST_PRODUCT_SUCCESS =
  "DELETE_WISHLIST_PRODUCT_SUCCESS";
export const DELETE_WISHLIST_PRODUCT_RESET = "DELETE_WISHLIST_PRODUCT_RESET";
export const DELETE_WISHLIST_PRODUCT_FAIL = "DELETE_WISHLIST_PRODUCT_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

// Define your wishlist constants
export const ADD_TO_WISHLIST_REQUEST = "ADD_TO_WISHLIST_REQUEST";
export const ADD_TO_WISHLIST_SUCCESS = "ADD_TO_WISHLIST_SUCCESS";
export const ADD_TO_WISHLIST_FAILURE = "ADD_TO_WISHLIST_FAILURE";

export const REMOVE_FROM_WISHLIST_REQUEST = "REMOVE_FROM_WISHLIST_REQUEST";
export const REMOVE_FROM_WISHLIST_SUCCESS = "REMOVE_FROM_WISHLIST_SUCCESS";
export const REMOVE_FROM_WISHLIST_FAILURE = "REMOVE_FROM_WISHLIST_FAILURE";

export const GET_WISHLIST_PRODUCTS_REQUEST = "GET_WISHLIST_PRODUCTS_REQUEST,";
export const GET_WISHLIST_PRODUCTS_SUCCESS = "GET_WISHLIST_PRODUCTS_SUCCESS,";
export const GET_WISHLIST_PRODUCTS_FAILURE = "GET_WISHLIST_PRODUCTS_FAILURE,";

export const LOAD_FAVOURITES_REQUEST = "LOAD_FAVOURITES_REQUEST,";
export const LOAD_FAVOURITES_SUCCESS = "LOAD_FAVOURITES_SUCCESS,";
export const LOAD_FAVOURITES_FAILURE = "LOAD_FAVOURITES_FAILURE,";
