import React from "react";
import { Col, Row, Typography } from "antd";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";

const AppFooter = () => {
  return (
    <div
      className="footerBlock"
      // style={{ backgroundColor: "#574D41", color: "white" }}
      style={{ backgroundColor: "#252525", color: "white" }}

      
    >
      <Row
        gutter={[16, 16]}
        justify="center"
        style={{ padding: "2rem", maxWidth: "100%" }}
      >
        <Col xs={24} sm={24} md={8} lg={8} xl={8} style={{ padding: "2rem" }}>
          <Typography variant="h5" style={{ color: "white" }}>
            About
          </Typography>
          <p className="about" style={{ marginRight: "0rem" }}>
            We would like to thank you for shopping with us. You can write us
            for any new thoughts at “cs@kitablovers.com” helping us to improvise
            for the reader satisfaction. We offer huge collection of New &
            Preloved-books in diverse category of Fiction, Non-fiction,
            Biographies, History, Teen fiction, Self -Help, Children...
          </p>
          <div className="icons">
            <a href="#">
              <i className="fa fa-facebook"></i>
            </a>
            <a href="#">
              <i className="fa fa-twitter"></i>
            </a>
            <FacebookIcon style={{ color: "white" }} />
            <InstagramIcon style={{ color: "white" }} />
          </div>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} style={{ padding: "2rem" }}>
          <Typography variant="h5" style={{ color: "white" }}>
            Address
          </Typography>
          <p>
            <i className="fa fa-map-marker"></i> Kh no 20/29, Nangli poona
            village, Delhi - 110036 India
          </p>
          <p>
            <i className="fa fa-phone"></i> +91-8860-525-525
          </p>
          <p>
            <i className="fa fa-envelope"></i>{" "}
            <a href="#" style={{ color: "white" }}>
              cs@kitablovers.com
            </a>
          </p>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} style={{ padding: "2rem" }}>
          <Typography variant="h5" style={{ color: "white" }}>
            kitablovers
          </Typography>
          <p className="menu">
            <a style={{ color: "white" }} href="#">
              Home
            </a>{" "}
            |
            <a style={{ color: "white" }} href="#">
              About
            </a>{" "}
            |
            <a style={{ color: "white" }} href="/termsandconditions">
              Return Policy
            </a>{" "}
            |
            <a style={{ color: "white" }} href="/termsandconditions">
              Terms and Conditions
            </a>{" "}
            |
            <a style={{ color: "white" }} href="/termsandconditions">
              Shipping Policy
            </a>{" "}
            |
            <a style={{ color: "white" }} href="#">
              Contact
            </a>
          </p>
          <p className="name">kitablovers &copy;</p>
        </Col>
      </Row>
    </div>
  );
};

export default AppFooter;
