import {
  Box,
  Button,
  Rating,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../App";

// const BASE_URL = "https://webbackend.kitablovers.com";

const SubmitReview = ({ productId, userId }) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const user_id = useSelector((state) => state?.user?.user?.id);

  const [headline, setHeadline] = useState("");

  const { id } = useParams();
  const submitReviewHandler = async () => {
    const reviewData = {
      user_id: user_id,
      product_id: parseInt(id),
      rating,
      comment,
    };

    try {
      const response = await fetch(BASE_URL + "/app/middleware/v1/setrating", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reviewData),
      });

      if (!response.ok) {
        throw new Error("Something went wrong when submitting the review.");
      }

      const data = await response.json();
      // Handle the response data as needed
      setSubmitSuccess(true);
    } catch (error) {
      setSubmitError(error.message);
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (submitSuccess) {
      // console.log("Success");
      // alert("Address added successfully"); // Show alert
      const timer = setTimeout(() => navigate(-1), 3000);
      return () => clearTimeout(timer);
    }
  }, [submitSuccess, navigate]);

  return (
    <Box sx={{ maxWidth: 600, m: "auto", p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Create Review
      </Typography>
      <Typography component="legend" gutterBottom></Typography>
      <Rating
        name="simple-controlled"
        value={rating}
        onChange={(event, newValue) => {
          setRating(newValue);
        }}
      />
      <TextField
        fullWidth
        label="Add a headline"
        variant="outlined"
        sx={{ mt: 2 }}
        value={headline}
        onChange={(e) => setHeadline(e.target.value)}
      />
      {/* <Stack direction="row" alignItems="center" spacing={2} sx={{ mt: 2 }}>
        <Button
          variant="outlined"
          component="label"
          // startIcon={<AddAPhotoIcon />}
        >
          Add a photo or video
          <input
            type="file"
            hidden
            accept="image/*, video/*"
            onChange={handleImageUpload}
          />
        </Button>
      </Stack> */}
      <TextField
        fullWidth
        label="Add a written review"
        variant="outlined"
        multiline
        rows={4}
        sx={{ mt: 2 }}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      <Button variant="contained" sx={{ mt: 2 }} onClick={submitReviewHandler}>
        Submit
      </Button>
    </Box>
  );
};
export default SubmitReview;
