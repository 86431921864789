import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Popper from "@mui/material/Popper";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import CategoryIcon from "@mui/icons-material/Category";
import { Link } from "react-router-dom";

export default function PopperPopupState({ categories }) {
  // Function to chunk categories into groups for columns
  const chunkCategories = (categories, size) => {
    let result = [];
    for (let i = 0; i < categories?.length; i += size) {
      result.push(categories?.slice(i, i + size));
    }
    return result;
  };

  const columnedCategories = chunkCategories(
    categories,
    Math.ceil(categories?.length / 3)
  );

  return (
    <>
      <PopupState
        variant="popper"
        popupId="demo-popup-popper"
        style={{ margin: "3rem" }}
      >
        {(popupState) => (
          <div>
            <Button
              style={{ color: "black", textTransform: "unset" }}
              {...bindToggle(popupState)}
            >
              {/* <CategoryIcon style={{ color: "#1b1303" }} /> */}
              <Typography
                variant="body1"
                sx={{ display: { xs: "none", sm: "block" } }}
                style={{
                  paddingLeft: "15px",
                  // color: "#1b1303",
                  color:"#f5ebe0",
                  fontSize: "12px",
                }}
              >
                All Categories
              </Typography>
            </Button>
            <Popper
              {...bindPopper(popupState)}
              transition
              style={{ width: "auto", padding: "1rem" }}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper sx={{ overflow: "auto", maxWidth: "100%" }}>
                    <Grid container spacing={2} padding={2}>
                      {columnedCategories?.map((column, index) => (
                        <Grid item xs={4} key={index}>
                          {column.map((category) => (
                            <Typography key={category.id} gutterBottom>
                              <Link to={`/search/?category_id=${category.id}`}>
                                {category.name}
                              </Link>
                            </Typography>
                          ))}
                        </Grid>
                      ))}
                    </Grid>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </div>
        )}
      </PopupState>
    </>
  );
}
