import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
} from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { Link, useParams } from "react-router-dom";
import { CancelOutlined } from "@mui/icons-material";
import axios from "axios";
import { BASE_URL } from "../../App";
import { fetchOrders } from "../../actions/orderAction";
import { useDispatch } from "react-redux";
import { loadUser, updateUser } from "../../actions/userAction";

const OrderCard = ({ order,scanner }) => {
  // console.log("OrderCard",order)
  const { id } = useParams();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogReturn, setOpenDialogReturn] = useState(false);
  const [openDialogReturnItem, setOpenDialogReturnItem] = useState(false);
  const orderStatus = order?.active_status.toLowerCase();
  const [buttonText, setButtonText] = useState(false);
  const [dialogMessage, setdialogMessage] = useState("");
  const [itemToReturn, setItemToReturn] = useState("");

  const [requestError, setRequestError] = useState("");

  useEffect(() => {
    dispatch(loadUser());
  }, [order, dispatch]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialogReturn = () => {
    setOpenDialogReturn(true);
  };

  const handleCloseDialogReturn = () => {
    setOpenDialogReturn(false);
  };

  const handleOpenDialogReturnItems = (id, name) => {
    setdialogMessage("Do You Want to Return " + name);
    setOpenDialogReturnItem(true);
    setItemToReturn(id);
  };

  const handleCloseDialogReturnItems = () => {
    setOpenDialogReturnItem(false);
  };

  // console.log(order);

  const handleCancelOrder = async () => {
    handleCloseDialog();

    try {
      // Send the POST request with cookies included in headers
      const response = await axios.post(
        BASE_URL + "/app/middleware/v1/cancelorder",
        {
          status: "cancelled",
          orderid: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      // console.log("Cancel Order Response:", response.data);
      if (response.data.error) {
        alert(
          response.data.message +
            " Please reach out to us for cancellation or return."
        );
      }
      dispatch(fetchOrders());
      dispatch(updateUser());
    } catch (error) {
      console.error("Error cancelling order:", error);
      alert(error.message);
      setRequestError(error.message);
    }
  };

  const handleReturnOrder = async () => {
    handleCloseDialogReturn();

    try {
      const response = await axios.post(
        BASE_URL + "/app/middleware/v1/cancelorder",
        {
          status: "returned",
          orderid: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      // console.log("Return Order Response:", response.data);
      dispatch(fetchOrders());

      if (response?.data?.error) {
        // console.log("Error cancelling order Req");
        setRequestError(response?.data?.message);
      }
    } catch (error) {
      console.error("Error cancelling order:", error);
    }
  };

  const handleReturnItems = async (orderId) => {
    // console.log("handleReturnItems id", orderId);
    handleCloseDialogReturnItems();
    if (order?.active_status.toLowerCase() === "delivered") {
      // setdialogMessage(productName);
      // handleOpenDialogReturnItems();

      try {
        const response = await axios.post(
          BASE_URL + "/app/middleware/v1/orderitemstatus",
          {
            status: "returned",
            orderid: orderId,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        // console.log("Return Order Response:", response.data);
        if (response.data.error) {
          alert(
            response.data.message +
              " Please reach out to us for cancellation or return."
          );
        }
        dispatch(fetchOrders());
      } catch (error) {
        console.error("Error cancelling order:", error);
        setRequestError(error.message);
      }
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case "received":
        return "We are Shipping Your Order";
      case "processed":
        return "Order Processed";
      case "shipped":
        return "Order Shipped";
      case "delivered":
        return "Order Delivered";
      default:
        return status;
    }
  };

  // console.log("order?.active_status", order?.active_status);

  useEffect(() => {
    // console.log(
    //   "order?.active_status.toLowerCase",
    //   order?.active_status.toLowerCase()
    // );
    switch (order?.active_status.toLowerCase()) {
      case "returned":
        setButtonText("Return Requested");
        break;
      case "cancelled":
        setButtonText("Order Cancelled");
        break;
      case "shipped":
        setButtonText("Cancel Order");
        // setButtonText("Cancel Order - Order Shipped");

        break;
      case "delivered":
        setButtonText("Request Return");
        // setButtonText("Request Return - Order Delivered");
        break;
      case "received":
        setButtonText("Cancel Order");
        // setButtonText("Cancel Order - Order Received");
        break;
        case "awaiting":
          setButtonText("Waiting for Payment, Please reach out to us if amount deducted");
        break;

      case "processed":
        setButtonText("Cancel Order");
        // setButtonText("Cancel Order - Processed");

        break;

      case "return_request_pending":
        setButtonText("Return Requested");
        // setButtonText("Return Requested - return_request_pending");

        break;

      default:
        setButtonText("Loading...");
    }
  }, [order]);

  // useEffect(() => {
  //   console.log(buttonText);
  // }, [buttonText]);

  const handleButtonFunction = async (id) => {
    if (order?.active_status.toLowerCase() === "delivered") {
      setOpenDialogReturn(true);
    }

    if (
      order?.active_status.toLowerCase() === "received" ||
      order?.active_status.toLowerCase() === "shipped" ||
      order?.active_status.toLowerCase() === "processed" 
    ) {
      setOpenDialog(true);
    }
  };

  // useEffect(() => {
  //   console.log("requestError", requestError);
  // }, [requestError]);

  return (
    <>
    
    
    
    <Card variant="outlined">
      <CardContent>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item>
            <Typography variant="subtitle1">
              Ordered on {formatDate(order?.date_added)}
            </Typography>
            <Typography variant="subtitle1">Order# {order?.id}</Typography>
            <Typography variant="subtitle1">
              Total: ₹{order?.final_total}
            </Typography>
            <Box my={2}>
          <Typography variant="subtitle1">Shipping Address</Typography>
          <Typography gutterBottom>{order?.username}</Typography>
          <Typography gutterBottom>{order?.address}</Typography>
        </Box>
          </Grid>
          <img src={scanner}/>
          
        </Grid>
        


        

        <Box my={2}>
          {order?.delivery_date && (
            <div>
              <Typography variant="subtitle1">
                Delivered {formatDate(order?.delivery_date)}
              </Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Package was handed to resident
              </Typography>
            </div>
          )}
        </Box>

        {order?.order_items &&
          order?.order_items.map((Orderitem) => (
            <Grid container spacing={2}>
              <div
                style={{
                  margin: "1rem",
                  padding: "1rem",
                  border: "1px solid gray",
                  width: "100%",
                }}
              >
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Link to={`/product/${Orderitem.product_id}`}>
                    <img
                      src={Orderitem?.image}
                      alt={Orderitem?.name}
                      style={{ width: "60%", height: "auto" }}
                    />
                  </Link>
                </Grid>
                <Grid item xs={12} sm={6} md={8} lg={9}>
                  <Typography variant="h6" gutterBottom>
                    {Orderitem?.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    {Orderitem?.variant_name}
                  </Typography>

                  <Grid container spacing={2} style={{ marginTop: 8 }}>
                    <Grid item xs={12} sm={4}>
                      <Button
                        variant="outlined"
                        startIcon={<AutorenewIcon />}
                        fullWidth
                        href={`/product/${Orderitem.product_id}`}
                      >
                        Buy It Again
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Button
                        variant="outlined"
                        startIcon={<RateReviewIcon />}
                        fullWidth
                        href={"/review/" + Orderitem.product_id}
                      >
                        Write a product review
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 8 }}>
                  {orderStatus === "delivered" && (
                    <Grid item xs={12} sm={4}>
                      <Button
                        variant="outlined"
                        startIcon={<LocalShippingIcon />}
                        fullWidth
                        onClick={
                          () =>
                            handleOpenDialogReturnItems(
                              Orderitem.id,
                              Orderitem.product_name
                            )
                          // handleReturnItems(
                          //   Orderitem.id,
                          //   Orderitem.product_name
                          // )
                        }
                        disabled={
                          orderStatus === "cancelled" ||
                          orderStatus === "returned" ||
                          Orderitem.active_status ===
                            "return_request_pending" ||
                          Orderitem.active_status === "returned"
                        }
                      >
                        {Orderitem.active_status === "return_request_pending"
                          ? "Return Requested"
                          : buttonText}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </div>
            </Grid>
          ))}

        {requestError !== "" && (
          <Alert severity="warning">
            Hmm... something is not right, please try return/cancellation for
            individual items
          </Alert>
        )}

        <Grid container>
          <Grid container spacing={2} style={{ marginTop: 8 }}>
            <Grid item xs={12} sm={4}>
              <Button
                variant="outlined"
                startIcon={<LocalShippingIcon />}
                fullWidth
                onClick={() => handleButtonFunction()}
                disabled={
                  orderStatus === "cancelled" || orderStatus === "returned"
                }
              >
                {buttonText}
              </Button>
            </Grid>
          </Grid>

          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Cancel Order"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to cancel this order?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                No
              </Button>
              <Button onClick={handleCancelOrder} color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openDialogReturn}
            onClose={handleCloseDialogReturn}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Cancel Order"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to Return this order?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialogReturn} color="primary">
                No
              </Button>
              <Button onClick={handleReturnOrder} color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openDialogReturnItem}
            onClose={handleCloseDialogReturnItems}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Cancel Order"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dialogMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialogReturnItems} color="primary">
                No
              </Button>
              <Button
                onClick={() => handleReturnItems(itemToReturn)}
                color="primary"
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Grid item xs={4} sm={4}></Grid>
          <Box my={2}>
            <Typography variant="subtitle1">Order Status</Typography>
            <ul>
              {order?.status.map(([status, date], index) => (
                <li key={index}>
                  <Typography variant="body2" color="textSecondary">
                    {date} - {getStatusLabel(status)}
                  </Typography>
                </li>
              ))}
            </ul>
          </Box>
        </Grid>
      </CardContent>
    </Card>
    </>
  );
};

export default OrderCard;
