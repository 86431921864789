import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import ProductCarouselAnt from "./ProductCarouselAntUI.js";
import ImageSlider from "./ImageSlider.js";
import "./home.css";
import { getAllFeaturedProducts } from "../../actions/productAction.js";
import { getSliderImages } from "../../actions/userAction.js";
import LinearLoader from "../layout/Loader/LinearLoader.jsx";

const selectTrendingProducts = (state) =>
  state.trendingproducts?.trendingProducts;
const selectSliderImages = (state) => state.imageSlider?.images?.data;

const Home = () => {
  // Redirection logic for mobile users
  // useEffect(() => {
  //   const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  //   if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
  //     window.location.href = "https://apps.apple.com/"; // Replace [YOUR_APP_ID] with your App Store app ID
  //   } else if (/android/i.test(userAgent)) {
  //     window.location.href =
  //       "https://play.google.com/store/apps/details?id=com.kitablovers.customer"; // Replace [YOUR_PACKAGE_NAME] with your Play Store package name
  //   }
  // }, []);

  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  const trendingProducts = useSelector(selectTrendingProducts);
  const slides = useSelector(selectSliderImages);

  useEffect(() => {
    dispatch(getAllFeaturedProducts());
    dispatch(getSliderImages());
    setLoaded(true);
  }, [dispatch]);

  if (!slides || !trendingProducts) {
    return <LinearLoader />;
  }

  return (
    <>
      <div
        // className="imageSliderContainer"
        className={`imageSliderContainer ${loaded ? "fadeIn" : ""}`}
        style={{
          justifyContent: "center",
          display: "flex",
          backgroundColor: "#fdf5e6",
        }}
      >
        {trendingProducts && slides && <ImageSlider slides={slides} />}
      </div>

      <div
        // className="trendingProductsContainer"
        className={`trendingProductsContainer ${loaded ? "fadeIn" : ""}`}
        style={{ paddingTop: "1rem", backgroundColor: "#fdf5e6" }}
      >
        <Container
          maxWidth="xl"
          className="HomeContainer"
          style={{
            paddingLeft: "unset",
            paddingRight: "unset",
          }}
        >
          {/* <Typography
            className="SectionTitle"
            style={{
              fontSize: "clamp(0.5rem, 0.3043rem + 3.4783vw, 1rem)",
              textTransform: "capitalize",
            }}
          >
            Today's Deals
          </Typography> */}
          <div className="productCarouselContainer">
            {trendingProducts && trendingProducts.length > 0 ? (
              trendingProducts.map((category) => (
                <ProductCarouselAnt key={category.id} category={category} />
              ))
            ) : (
              <p>No trending products available</p>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default Home;
