import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import CategoryIcon from "@mui/icons-material/Category";
import { Button, Typography } from "@mui/material";
import { AccountCircle, ShoppingCart } from "@mui/icons-material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SearchBar from "./SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/userAction";
import { useNavigate } from "react-router-dom";

export default function TemporaryDrawer() {
  const { user, isAuthenticated } = useSelector((state) => state.user);

  const [state, setState] = useState({
    search: false,
    hamburger: false,
    categories: false,
  });

  const handleInputEvent = (event) => {
    event.stopPropagation();
  };
  
  const toggleDrawer = (drawer, open) => (event) => {
    if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
      return;
    }
  
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [drawer]: open });
  };


  
  // Define the content for the search drawer
  const searchContent = (
    <Box
      role="presentation"
      onClick={toggleDrawer("search", false)}
      onKeyDown={toggleDrawer("search", false)}
      style={{paddingTop:"3.5rem"}}
    >
      {/* Add your search-related content here */}
      <List>
        <ListItem disablePadding onClick={(event) => event.stopPropagation()}>
          <ListItemButton>
            <SearchBar handleInputEvent={handleInputEvent} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  // Define the content for the hamburger (menu) drawer
  const hamburgerContentTemp = (
    <Box
      role="presentation"
      onClick={toggleDrawer("hamburger", false)}
      onKeyDown={toggleDrawer("hamburger", false)}
      style={{paddingTop:"3.5rem"}}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <Button href="/cart" style={{ alignItems: "center" }}>
              <ShoppingCart style={{ color: "#1b1303" }} />
            </Button>
          </ListItemButton>

          <ListItemButton>
            <Button href="/wishlist" style={{ alignItems: "center" }}>
              <FavoriteIcon style={{ color: "#1b1303" }} />
            </Button>
          </ListItemButton>

          <ListItemButton>
            <ListItemText primary="Menu Item 1" />
          </ListItemButton>

          <ListItemButton>
            <Button
              href="/loadtheboxevent"
              variant="contained"
              className="shineButton"
              style={{ textTransform: "unset", marginLeft: "1rem" }}
            >
              <Typography
                variant="body1"
                style={{ color: "black", fontSize: "12px" }}
              >
                Load the Box Event
              </Typography>
            </Button>
          </ListItemButton>
        </ListItem>
        {/* More menu items... */}
      </List>
    </Box>
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logout());
    navigate("/")
  };

  // Define the content for the categories drawer
  const hamburgerContent = (
    <Box
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      sx={{ width: "auto" }}
      style={{paddingTop:"3.5rem"}}
    >
      {isAuthenticated && (
        <List>
          {[
            { name: "Load the box Event", id: 134, url: "/loadtheboxevent" },
            { name: "Profile", id: 122, url: "/profile" },
            { name: "My Orders", id: 133, url: "/orders" },
            
          ].map((category) => (
            <ListItem key={category.id} disablePadding>
              <ListItemButton href={`${category.url}`}>
                <ListItemText>
                  <Typography
                    variant="body1"
                    style={{ fontSize: "12px", color: "black" }}
                  >
                    {category.name}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))}

          <ListItemButton onClick={handleLogout}>
            <ListItemText>
              <Typography
                variant="body1"
                style={{ fontSize: "12px", color: "black" }}
              >
                Logout
              </Typography>
            </ListItemText>
          </ListItemButton>
        </List>
      )}
    </Box>
  );

  // Define the content for the categories drawer
  const categoriesContent = (
    <Box
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      sx={{ width: "auto" }}
      style={{paddingTop:"3.5rem"}}
    >
      <List>
        {[
          { name: "Literary Fiction", id: 122 },
          // { name: "Surprise Box", id: 161 },
          { name: "Fantasy", id: 131 },
          { name: "Thriller", id: 125 },
          { name: "Romance", id: 129 },
          { name: "Horror", id: 127 },
          { name: "Historical Fiction ", id: 123 },
          { name: "Children's Fiction", id: 135 },
          { name: "Biographies", id: 137 },
          { name: "Self Help ", id: 140 },
          { name: "Science", id: 145 },
          { name: "Religion and Spirituality", id: 149 },
          // ... add all other categories here
        ].map((category) => (
          <ListItem key={category.id} disablePadding>
            <ListItemButton href={`/search/?category_id=${category.id}`}>
              <ListItemText>
                <Typography
                  variant="body1"
                  style={{ fontSize: "12px", color: "black" }}
                >
                  {category.name}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}

<ListItem key={1} disablePadding>
            <ListItemButton href={`/surprisebox`}>
              <ListItemText>
                <Typography
                  variant="body1"
                  style={{ fontSize: "12px", color: "black" }}
                >
                  Surprise Box
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>


      </List>
    </Box>
  );

  return (
    <>
    <div
      style={{
        // backgroundColor: "#c6ac8e",
        // margin: "0 auto",
        // display: "flex",
        // flexWrap: "wrap",
        // flexDirection: "row",
        // alignContent: "center",
        // justifyContent: "center",

        boxShadow: "unset", 
        zIndex:"10000000",
        position:"fixed",
          width: "100%",
          backdropFilter: "blur(50px)",
          backgroundColor: "rgb(198 172 142 / 100%)",
          // backgroundColor: "rgb(37 37 37 / 75%)",
          justifyContent: "center",
          display: "flex",
          // height:"3.5rem",
          flexWrap: "wrap",
      }}
    >
      <Button href="/">
        <img
          src={"../kitablovers-removebg.png"}
          alt="Logo"
          style={{ height: "22px", filter: "brightness(0) invert(0)" }}
        />
      </Button>

      {/* Search Button */}
      <IconButton onClick={toggleDrawer("search", true)}>
        <SearchIcon style={{ color: "#1b1303", alignItems: "center" }} />
      </IconButton>
      <Drawer
        anchor="top"
        open={state.search}
        onClose={toggleDrawer("search", false)}
        BackdropProps={{
          style: {
            backdropFilter: "blur(14px)",
            
          },
        }}
      >
        {searchContent}
      </Drawer>

      {/* Categories Button */}
      <IconButton onClick={toggleDrawer("categories", true)}>
        <CategoryIcon style={{ color: "#1b1303" }} />
      </IconButton>
      <Drawer
        anchor="top"
        open={state.categories}
        onClose={toggleDrawer("categories", false)}
        BackdropProps={{
          style: {
            backdropFilter: "blur(14px)",
          },
        }}
      >
        {categoriesContent}
      </Drawer>

   {isAuthenticated &&   <Button href="/cart" style={{ alignItems: "center" }}>
        <ShoppingCart style={{ color: "#1b1303" }} />
      </Button>}

    {isAuthenticated &&  <Button href="/wishlist" style={{ alignItems: "center" }}>
        <FavoriteIcon style={{ color: "#1b1303" }} />
      </Button>
}
      {!isAuthenticated && (
        <Button href="/login" style={{ alignItems: "center" }}>
          {/* <AccountCircle style={{ color: "black" }} />  */}
          <Typography style={{color:"black"}}>Login/Register</Typography>
        </Button>
      )}

      {/* Hamburger Button */}
      <div style={{ left: 0 }}>
      {isAuthenticated &&  <IconButton onClick={toggleDrawer("hamburger", true)}>
          <MenuIcon style={{ color: "#1b1303" }} />
        </IconButton>}
        <Drawer
          anchor="top"
          open={state.hamburger}
          onClose={toggleDrawer("hamburger", false)}
          BackdropProps={{
            style: {
              backdropFilter: "blur(14px)",
            },
          }}
        >
          {hamburgerContent}
        </Drawer>
      </div>
    </div>
    <div style={{paddingTop:"2.5rem"}}></div>
    </>
  );
}
