import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
  Alert,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, register } from "../../actions/userAction";
import { useNavigate, Navigate } from "react-router-dom";
import { BASE_URL } from "../../App";
import OTPSignup from "./OTPAuth/OTPSignup";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { Toaster, toast } from "react-hot-toast";

import { IconButton, Input, InputAdornment, Tooltip } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { auth } from "../../firebase";

function Signup() {
  const { mobile } = "";

  useEffect(() => {
    if (mobile) {
      setPh("+91" + mobile);
    }
  }, [mobile]);

  const navigate = useNavigate();

  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("+91" + mobile);
  // const [ph, setPh] = useState( "");

  const [showOtp, setShowOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [isPasswordChangeSuccessful, setIsPasswordChangeSuccessful] =
    useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false); // Add state to track OTP verification

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  function onCaptchaVerify() {
    if (!window.RecaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          // size: "invisible",
          size: "normal",
          callback: (response) => {
            verifyphonenumber();
          },
          "expired-callback": () => {},
        }
      );
    }
  }

  function verifyphonenumber(event) {
    event.preventDefault();
    setLoading(true);
    onCaptchaVerify();
    const appVerifier = window.recaptchaVerifier;
    const phoneNumber = "+" + ph;

    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOtp(true);
        toast.success("OTP Sent Successfully");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  }

  const triggerRegistration = () => {
    // console.log("triggerRegistration", isOtpVerified);
    dispatch(register({ ...formData, country_code: "+91" }));
  };

  function onOtpverify() {
    window.confirmationResult
      .confirm(otp)
      .then(async (result) => {
        setUser(result.user);
        setLoading(false);
        setShowOtp(false); // Hide OTP input
        setIsOtpVerified(true); // Set OTP verification to true
        await triggerRegistration(); // Call registration function after OTP is verified
      })
      .catch((error) => {
        // console.log(error.message);
        toast.error(error.message);
      });
  }

  useEffect(() => {
    let timeout;
    if (isPasswordChangeSuccessful) {
      timeout = setTimeout(() => {
        navigate("/"); // Redirects to the home page
      }, 5000); // 5000 milliseconds = 5 seconds
    }

    return () => clearTimeout(timeout); // Clear timeout if component unmounts
  }, [isPasswordChangeSuccessful, navigate]);

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  // console.log("mobile", mobile);
  const dispatch = useDispatch();

  const { error, isAuthenticated } = useSelector((state) => state.user);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: mobile?.slice(2),
    password: "",
    confirmPassword: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const checkUserExists = async (mobileNumber) => {
    try {
      const response = await fetch(BASE_URL + "/app/middleware/v1/verifyuser", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ mobile: mobileNumber }),
      });
      const data = await response.json();
      if (data.error) {
        setFormErrors({
          ...formErrors,
          mobile: "User already exists with this mobile number",
        });
      }
    } catch (error) {
      console.error("Error checking user existence", error);
    }
  };

  // useEffect(() => {
  //   if (formData?.mobile?.length === 10) {
  //     checkUserExists(formData.mobile);
  //   }
  //   if (formData?.mobile?.length != 10) {
  //     setFormErrors({
  //       ...formErrors,
  //       mobile: "",
  //     });
  //   }
  // }, [formData.mobile]);
  useEffect(() => {
    if (formData?.mobile?.length === 10) {
      checkUserExists(formData.mobile);
    } else {
      // Remove mobile error if the length is not 10
      const newErrors = { ...formErrors };
      delete newErrors.mobile;
      setFormErrors(newErrors);
    }
  }, [formData.mobile]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const mobileRegex = /^[0-9]{10}$/;

    if (!values.email) {
      errors.email = "Email is required";
    } else if (!regex.test(values.email)) {
      errors.email = "Invalid email format";
    }

    if (!values.mobile) {
      errors.mobile = "Mobile number is required";
    } else if (!mobileRegex.test(values.mobile)) {
      errors.mobile = "Invalid mobile number";
    }

    if (!values.password) {
      errors.password = "Password is required";
    }

    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }

    return errors;
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setFormErrors(validate(formData));
  //   setIsSubmitting(true);
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setFormErrors(validate(formData));
  //   setIsSubmitting(true);

  //   if (Object.keys(formErrors)?.length === 0 && isSubmitting) {
  //     verifyphonenumber(); // Call OTP sending function instead of registering immediately
  //   }
  // };
  const handleSubmit = (e) => {
    e.preventDefault();
    const newFormErrors = validate(formData);
    setFormErrors(newFormErrors);

    // Only proceed with submitting if there are no errors
    if (Object.keys(newFormErrors).length === 0) {
      setIsSubmitting(true);
      verifyphonenumber(); // Call OTP sending function
    }
  };

  useEffect(() => {
    let timeout;
    if (isPasswordChangeSuccessful) {
      timeout = setTimeout(() => {
        navigate("/");
      }, 5000);
    }

    return () => {
      clearTimeout(timeout);
      if (isPasswordChangeSuccessful) {
        setIsSubmitting(false); // Reset isSubmitting when navigating away
      }
    };
  }, [isPasswordChangeSuccessful, navigate]);

  // useEffect(() => {
  //   if (Object.keys(formErrors)?.length === 0 && isSubmitting) {
  //     dispatch(register({ ...formData, country_code: "+91" }));
  //   }
  // }, [formErrors]);

  useEffect(() => {
    if (error) {
      setFormErrors({ submit: error });
      dispatch(clearErrors());
    }
    if (isAuthenticated) {
      navigate("/");
    }
  }, [error, isAuthenticated, navigate, dispatch]);

  return (
    <>
      {isAuthenticated && <Navigate to="/" replace />}
      <Container component="main" maxWidth="xs" style={{ minHeight: "60rem" }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Create New Account
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoComplete="name"
              autoFocus
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={handleChange}
              error={!!formErrors.email}
              helperText={formErrors.email}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="mobile"
              label="Mobile Number"
              defaultValue={mobile?.slice(2)}
              disabled={mobile ? true : false}
              name="mobile"
              autoComplete="mobile"
              onChange={handleChange}
              error={!!formErrors.mobile}
              helperText={formErrors.mobile}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleChange}
              error={!!formErrors.password}
              helperText={formErrors.password}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="confirmPassword"
              autoComplete="current-password"
              onChange={handleChange}
              error={!!formErrors.confirmPassword}
              helperText={formErrors.confirmPassword}
            />
            {formErrors.submit && (
              <Alert severity="error">{formErrors.submit}</Alert>
            )}
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />

            {!isSubmitting && (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={Object.keys(formErrors).length > 0}
              >
                verify phone Number and Sign Up
              </Button>
            )}

            {isSubmitting && (
              <div className={`d-flex justify-content-center`}>
                <Toaster toastOptions={{ duration: 4000 }} />
                {!user ? (
                  <div
                    className={`row position-absolute mt-5 bg-dark text-white p-5`}
                  >
                    {showOtp ? (
                      <div>
                        <Typography
                          style={{
                            paddingBottom: "2rem",
                            paddingTop: "2rem",
                          }}
                        >
                          Enter Your OTP{" "}
                        </Typography>
                        <OtpInput
                          value={otp}
                          onChange={setOtp}
                          numInputs={6}
                          shouldAutoFocus
                          renderInput={(props) => (
                            <input
                              {...props}
                              style={{ width: "30px", marginRight: "12px" }}
                            />
                          )}
                        />
                        <div
                          className="d-flex justify-content-center"
                          style={{ paddingTop: "2rem" }}
                        >
                          <Button
                            className="btn btn-primary mt-3 w-75 "
                            onClick={onOtpverify}
                            disabled={loading}
                            variant="contained"
                          >
                            {loading && (
                              <span className="spinner-border spinner-border-sm mr-2"></span>
                            )}
                            Verify OTP
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div style={{ paddingTop: "2rem" }}>
                        <Typography style={{ paddingBottom: "2rem" }}>
                          Verify Your Phone Number
                        </Typography>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            paddingTop: "2rem",
                          }}
                        >
                          <PhoneInput
                            country={"in"}
                            value={ph}
                            onChange={setPh}
                          />
                        </div>

                        <div
                          className="d-flex justify-content-center"
                          style={{
                            paddingTop: "2rem",
                            paddingBottom: "2rem",
                          }}
                        >
                          <Tooltip title="OTP SMS WILL BE SENT TO YOUR PHONE NUMBER">
                            <Button
                              className="btn btn-primary mt-3 w-75 "
                              onClick={verifyphonenumber}
                              disabled={loading}
                              variant="contained"
                            >
                              {loading && (
                                <span className="spinner-border spinner-border-sm mr-2"></span>
                              )}
                              Send OTP
                            </Button>
                          </Tooltip>
                        </div>
                        <div id="recaptcha-container" className="mt-4"></div>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            )}

            <Grid container>
              <Grid item xs>
                <Link href="/resetpassword" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/login" variant="body2">
                  {"Have an account? Sign in"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>

      {/* {isSubmitting && (
        <Container style={{ minHeight: "55rem", margin: "0 auto" }}>
          <div className={`d-flex justify-content-center`}>
            <Toaster toastOptions={{ duration: 4000 }} />
            {!user && !isPasswordChangeSuccessful ? (
              <div
                className={`row position-absolute mt-5 bg-dark text-white p-5`}
              >
                {showOtp ? (
                  <div>
                    <Typography
                      style={{ paddingBottom: "2rem", paddingTop: "2rem" }}
                    >
                      Enter Your OTP{" "}
                    </Typography>
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      shouldAutoFocus
                      renderInput={(props) => (
                        <input
                          {...props}
                          style={{ width: "30px", marginRight: "12px" }}
                        />
                      )}
                    />
                    <div
                      className="d-flex justify-content-center"
                      style={{ paddingTop: "2rem" }}
                    >
                      <Button
                        className="btn btn-primary mt-3 w-75 "
                        onClick={onOtpverify}
                        disabled={loading}
                        variant="contained"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        {loading && (
                          <span className="spinner-border spinner-border-sm mr-2"></span>
                        )}
                        Verify OTP
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div style={{ paddingTop: "2rem" }}>
                    <Typography style={{ paddingBottom: "2rem" }}>
                      Verify Your Phone Number
                    </Typography>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        paddingTop: "2rem",
                      }}
                    >
                      <PhoneInput country={"in"} value={ph} onChange={setPh} />
                    </div>

                    <div
                      className="d-flex justify-content-center"
                      style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
                    >
                      <Tooltip title="OTP SMS WILL BE SENT TO YOUR PHONE NUMBER">
                        <Button
                          className="btn btn-primary mt-3 w-75 "
                          onClick={verifyphonenumber}
                          disabled={loading}
                          variant="contained"
                        >
                          {loading && (
                            <span className="spinner-border spinner-border-sm mr-2"></span>
                          )}
                          Send OTP
                        </Button>
                      </Tooltip>
                    </div>
                    <div id="recaptcha-container" className="mt-4"></div>
                  </div>
                )}
              </div>
            )
             : isPasswordChangeSuccessful ? (
              <div className="row position-absolute text-white p-5">
                <p>Password Changed Successfully! Redirecting to home page</p>
              </div>
            ) : (
              <div className="row position-absolute bg-dark text-white p-5">
                <TextField
                  disabled
                  label="Phone Number"
                  value={ph.slice(2)}
                  // variant="filled"
                  margin="normal"
                />

                <Typography
                  style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
                >
                  Enter New Password
                </Typography>
                <Input
                  type={showPassword ? "text" : "password"}
                  // type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="New Password"
                  className="mb-3"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <div
                  className="d-flex justify-content-center"
                  style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
                >
                  <Button
                    className="btn btn-primary mt-3 w-75 "
                    onClick={handleChangePassword}
                    variant="contained"
                  >
                    Change Password
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Container>
      )} */}
    </>
  );
}

export default Signup;
