// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
// import { Button, Card, CardContent, CardMedia, Typography } from "@mui/material";
// import { InputNumber } from "antd";
// import { getCart, modifyCart } from "../../actions/cartAction";
// import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
// import "./Card.css";
// import { loadFavourites, removeFromWishlist } from "../../actions/wishlistAction";

// const WishlistCard = ({ product }) => {
//   const dispatch = useDispatch();
//   const { isAuthenticated, user } = useSelector((state) => state.user);
//   const userId = user?.id;
//   const variants = product?.variants || [];
//   const [selectedVariant, setSelectedVariant] = useState(variants[0]);

//   useEffect(() => {
//     const firstAvailableVariant = variants.find((variant) => variant.availability !== "0");
//     setSelectedVariant(firstAvailableVariant);
//   }, [variants]);

//   const handleVariantClick = (variant) => {
//     setSelectedVariant(variant);
//   };

//   const removeFromWishlistHandler = () => {
//     dispatch(removeFromWishlist(userId, selectedVariant.product_id));
//     dispatch(loadFavourites());
//   };

//   const modifyCartHandler = () => {
//     dispatch(modifyCart(userId, selectedVariant.id, 1));
//   };

//   const calculateDiscount = (price, specialPrice) => {
//     if (price && specialPrice) {
//       return Math.round((1 - specialPrice / price) * 100);
//     }
//     return 0;
//   };

//   return (
//     <Card sx={{ display: "flex", width: "100%", boxShadow: "unset", border: "5px solid #564c40", margin: "1rem", borderRadius: "0px" }} style={{width:"100%", height:"auto"}}>
//       <CardContent sx={{ flex: "1 0 auto" }}>
//         <Typography component="div" variant="h5" style={{ marginLeft: "2rem" }}>
//           <Link to={"/product/" + product.id} style={{ all: "unset", cursor: "pointer" }}>
//             {product.name}
//           </Link>
//         </Typography>
//         <div style={{ marginTop: "1rem" }}>
//           <Button variant="contained" onClick={modifyCartHandler} style={{ marginLeft: "2rem", backgroundColor: "#ffcb69", color: "black",fontSize:"12px" }}>
//             Move to cart
//           </Button>
//           <Button variant="text" onClick={removeFromWishlistHandler} style={{ marginLeft: "2rem", color: "#686763" ,fontSize:"12px"}}>
//             Remove from Wishlist
//           </Button>
//         </div>
//         <div className="variants" style={{ paddingTop: "1rem", paddingLeft: "1rem", marginLeft: "1rem" }}>
//           <label>Select Variant:</label>
//           <div className="variant-selector">

//             <div className="variant-buttons">
//               {variants.map((variant) => (
//                 <Button
//                   key={variant.id}
//                   className={`${selectedVariant && selectedVariant.id === variant.id ? "selected" : ""} variant-button`}
//                   onClick={() => handleVariantClick(variant)}
//                   variant="contained"
//                   style={variant.availability == 0 ? { color: "#adb5bd",fontSize:"12px",height:"2rem",width:"auto",padding:"unset" } : { color: "#edede9",fontSize:"12px",height:"2rem" }}
//                   disabled={variant.availability == 0}

//                 >
//                   <Typography style={{fontSize:"12px"}}>
//                   {variant.variant_values} {variant.availability == "0" ? <Typography variant="body" style={{height:"2rem", paddingLeft: "1rem",fontSize:"12px" }}> (Out of stock)</Typography> : ""}
//                   </Typography>
//                 </Button>
//               ))}
//             </div>

//           </div>
//           <div style={{ display: "flex", flexDirection: "row", paddingTop: "1rem" ,fontSize:"12px"}}>
//               <div style={{ color: "black", fontWeight: "bold" }} id="selectedPriceDisplay">
//                 Price: ₹{selectedVariant && selectedVariant?.special_price}
//               </div>
//               <div id="originalPriceDisplay" style={{ textDecoration: "line-through", color: "#696663", paddingLeft: "1rem" }}>
//                 Price: ₹{selectedVariant && selectedVariant?.price}
//               </div>
//               <div className="discount">
//                 {calculateDiscount(selectedVariant?.price, selectedVariant?.special_price)}% Off
//               </div>
//             </div>
//         </div>
//       </CardContent>
//       <Link to={"/product/" + product.id}>
//         <CardMedia
//           component="img"
//           sx={{ width: 151, padding: "1rem" }}
//           image={product.image}
//           alt={product.name}
//           style={{ maxWidth: "150px" }}
//         />
//       </Link>
//     </Card>
//   );
// };

// export default WishlistCard;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import {
  removeFromWishlist,
  loadFavourites,
} from "../../actions/wishlistAction";
import { modifyCart } from "../../actions/cartAction";

const WishlistCard = ({ product }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user?.id);
  const [selectedVariant, setSelectedVariant] = useState(
    product?.variants?.[0]
  );

  useEffect(() => {
    const firstAvailableVariant = product.variants.find(
      (variant) => variant.availability !== "0"
    );
    setSelectedVariant(firstAvailableVariant);
  }, [product.variants]);

  const handleVariantClick = (variant) => setSelectedVariant(variant);

  const removeFromWishlistHandler = async () => {
    await dispatch(removeFromWishlist(userId, selectedVariant.product_id));
    dispatch(loadFavourites());
  };

  const modifyCartHandler = () =>{
    
    dispatch(modifyCart(userId, selectedVariant.id, 1));
    alert("Item added to cart" )
  }

  const calculateDiscount = (price, specialPrice) =>
    price && specialPrice ? Math.round((1 - specialPrice / price) * 100) : 0;

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        boxShadow: "unset",
        border: "5px solid #564c40",
        m: 1,
        borderRadius: "0px",
      }}
    >
      <Grid container>
        <Grid item xs={8} sm={8}>
          <CardContent>
            <Typography variant="h5">
              <Link
                to={`/product/${product.id}`}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  fontSize: "clamp(0.2rem, 0.3043rem + 3.4783vw, 2rem)",
                }}
              >
                {product.name}
              </Link>
            </Typography>
            <Typography
              color="text.secondary"
              style={{
                fontSize: "clamp(0.2rem, 0.3043rem + 3.4783vw, 1rem)",
                paddingBottom:"0.7rem"
              }}
            >
              Variant: {selectedVariant?.variant_values}
            </Typography>
            {/* <div style={{ display: "flex", flexDirection: "column", gap: "1rem", marginTop: "1rem", marginLeft: "2rem" }}> */}
            <Button
              variant="contained"
              onClick={modifyCartHandler}
              sx={{
                backgroundColor: "#ffcb69",
                color: "black",
                fontSize: "clamp(0.2rem, 0.3043rem + 3.4783vw, 0.7rem)",
              }}
              style={{marginBottom:"1rem"}}
            >
              Move to cart
            </Button>
            <Button
              variant="text"
              onClick={removeFromWishlistHandler}
              sx={{
                color: "#686763",
                fontSize: "clamp(0.2rem, 0.3043rem + 3.4783vw, 0.7rem)",
              }}
              style={{marginBottom:"1rem"}}

            >
              Remove from Wishlist
            </Button>
            {/* </div> */}
           {product?.variants?.length > 1 && <div
              style={{
                paddingTop: "0rem",
                paddingLeft: "0rem",
                marginLeft: "0rem",
              }}
            >
              <Typography variant="body2" 
              style={{marginBottom:"1rem"}}
              
              
              >Select Variant:</Typography>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  marginTop: "0px",
marginBottom:"1rem"

                }}
              >
                {product.variants.map((variant) => (
                  <Button
                    key={variant.id}
                    variant="outlined"
                    onClick={() => handleVariantClick(variant)}
                    disabled={variant.availability === "0"}
                    sx={{
                      fontSize: "clamp(0.2rem, 0.3043rem + 3.4783vw, 0.7rem)",
                    }}
                  >
                    {variant?.variant_values}{" "}
                    {variant?.availability === "0" && "(Out of stock)"}
                  </Button>
                ))}
              </div>
            </div>}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "0rem",
                paddingLeft: "0rem",
                marginLeft: "0rem",
                fontSize: "clamp(0.2rem, 0.3043rem + 3.4783vw, 0.7rem)",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                Price: ₹{selectedVariant?.special_price}
              </Typography>
              <Typography
                sx={{ textDecoration: "line-through", color: "#696663", ml: 1 }}
              >
                ₹{selectedVariant?.price}
              </Typography>
              <Typography sx={{ ml: 1 }}>
                {calculateDiscount(
                  selectedVariant?.price,
                  selectedVariant?.special_price
                )}
                % Off
              </Typography>
            </div>
          </CardContent>
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link to={`/product/${product.id}`}>
            <CardMedia
              component="img"
              image={product.image}
              alt={product.name}
              sx={{ width: 121, p: 1, maxWidth: "150px" }}
            />
          </Link>
        </Grid>
      </Grid>
    </Card>
  );
};

export default WishlistCard;
