import { Container, Typography } from '@mui/material'

import React from 'react'

const OrdersError = () => {
  return (
    <>
    <Container style={{minHeight:"55rem"}}>
        <Typography variant="h5" style={{display:"flex", justifyContent:"center",paddingTop:"5rem"}}>
        Something went wrong while placing your order, your wallet amount is not deducted,
        Any payment deducted will be refunded back.
        </Typography>
    </Container>
    </>
  )
}

export default OrdersError