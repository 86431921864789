import {
  GET_CART_FAIL,
  GET_CART_REQUEST,
  GET_CART_SUCCESS,
} from "../constants/cartConstants";

const initialState = {
  cartData: null,
  loading: false,
  error: null,
};

export const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CART_REQUEST:
      return { ...state, loading: true, error: null };

    case GET_CART_SUCCESS:
      return {
        ...state,
        cartData: action.payload,
        loading: false,
        error: null,
      };

    case GET_CART_FAIL:
      return { ...state, error: action.payload, loading: false };

    default:
      return state;
  }
};
