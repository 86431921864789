import { useState, useEffect } from "react";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import moon from "./icons/white-circle-svgrepo-com.svg";
import "./slider.css";

const ImageSlider = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const showNextImage = () => {
    setCurrentIndex((index) => (index === slides.length - 1 ? 0 : index + 1));
  };

  const showPreviousImage = () => {
    setCurrentIndex((index) => (index === 0 ? slides.length - 1 : index - 1));
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      showNextImage();
    }, 7000);

    return () => {
      clearInterval(timer);
    };
  }, [currentIndex]);

  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <div
        // style={{
        //   width: "100%",
        //   height: "auto",
        //   display: "flex",
        //   overflow: "hidden",
        //   margin: "0 auto",
        //   backgroundColor: "#fdf5e7",
        // }}
        className="responsiveDiv"

      >
        {slides &&
          slides.map((images, index) => (
            <img
              key={images.title}
              src={images.image}
              className="img-slider-img"
              style={{
                cursor:images.link === ""? "":"pointer",
                translate: `${-100 * currentIndex}%`,
              }}
              alt={`slide-${index}`}
              onClick={() => {
                if (images.link) { // Checks if images.link is not an empty string
                  window.open(images.link, '_blank'); // Opens the link in a new tab
                }
              }}
            ></img>

          ))}
      </div>

      <div
        style={{
          all: "unset",
          position: "absolute",
          bottom: "0.5rem",
          left: "50%",
          translate: "-50%",
          display: "flex",
          gap: "0.25rem",
          padding: "2rem",
        }}
      >
        {slides &&
          slides.map((_, index) => (
            <button
              key={index}
              className="img-slider-dot-btn"
              onClick={() => handleDotClick(index)}
            >
              {index === currentIndex ? (
                <img src={moon} alt={`dot-${index}`} />
              ) : (
                <img
                  style={{ opacity: "0.5" }}
                  src={moon}
                  alt={`dot-${index}`}
                />
              )}
            </button>
          ))}
      </div>

      <button
        onClick={showPreviousImage}
        className="img-slider-button"
        style={{ left: 0, stroke: "white" }}
      >
        {/* <NavigateBeforeIcon /> */}
      </button>

      <button
        onClick={showNextImage}
        className="img-slider-button"
        style={{ right: 0 }}
      >
        {/* <NavigateNextIcon /> */}
      </button>
    </div>
  );
};

export default ImageSlider;
