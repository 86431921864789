import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategory,
  getProduct,
  getProducts,
  getSimilarProducts,
} from "../../actions/productAction";
import {
  Box,
  Button,
  Container,
  Grid,
  Pagination,
  Rating,
  Typography,
} from "@mui/material";
import CategoriesComponent from "./RenderCategories";
import SearchCard from "./SearchCard";
import LinearLoader from "../layout/Loader/LinearLoader";
import "./SearchPage.css"

const ProductsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  

  const userData = useSelector((state) => state.products);
  const categories = useSelector(
    (state) =>
      state.categories &&
      state.categories.categories &&
      state.categories.categories.data
  );

  const loadingProducts = useSelector(
    (state) => state?.searchedProducts?.loading
  );

  const totalProducts = useSelector(
    (state) => state?.searchedProducts?.products?.total
  );

  const products = useSelector(
    (state) => state?.searchedProducts?.products?.data
  );

  // console.log("totalProducts",products)

  const similarproducts = useSelector(
    (state) =>
      state.similarProducts &&
      state.similarProducts.products &&
      state.similarProducts.products.data
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const location = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProducts = async () => {
      const query = new URLSearchParams(location.search);
      const params = {
        search: query.get("search"),
        category_id: query.get("category_id"),
        brand_id: query.get("brand_id"),
        tags: query.get("tags"),
        attribute_value_ids: query.get("attribute_value_ids"),
        sort: query.get("sort"),
        order: query.get("order"),
        limit: itemsPerPage,
        // offset:0
        offset: (currentPage - 1) * itemsPerPage,
      };

      // Dispatch getProducts with the current search parameters
      dispatch(getProducts(params));
    };

    fetchProducts();
  }, [dispatch, location.search]);

  const fetchProducts = async () => {
    const query = new URLSearchParams(location.search);
    const params = {
      search: query.get("search"),
      category_id: query.get("category_id"),
      brand_id: query.get("brand_id"),
      tags: query.get("tags"),
      attribute_value_ids: query.get("attribute_value_ids"),
      sort: query.get("sort"),
      order: query.get("order"),
      limit: 100,
      // offset:0
      offset: (currentPage - 1) * itemsPerPage,
    };

    // console.log(
    //   "(currentPage - 1) * itemsPerPage",
    //   (currentPage - 1) * itemsPerPage
    // );

    // Dispatch getProducts with the current search parameters
    dispatch(getProducts(params));
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    // console.log("page", page);
    fetchProducts(page);
    // fetchSimilarProducts(page);
    // console.log("parseInt(userData.total)", userData);
  };

  useEffect(() => {
    if (products?.length > 0) {
      const categoryId =
        products[0].category_id ||
        new URLSearchParams(location.search).get("category_id");
      dispatch(
        getSimilarProducts({ category_id: categoryId, limit: 100, offset: 0 })
      );
    }

    // console.log("similarproducts?.length products", similarproducts?.length);
    if (similarproducts && similarproducts.length < 5) {
      dispatch(getSimilarProducts({}));
    }
  }, [dispatch, products]); // Added products to the dependency array

  // const [products, setProducts] = useState([]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  if(loadingProducts){
    return(
      <LinearLoader/>
    )
  }
  return (
    <>
     
        
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
      
        <div className="mobile-hide">
          <Container
            style={{
              width: "20rem",
              minHeight: "100%",
              // backgroundColor: "orange",
              backgroundColor: "#fdf5e6",
            }}
          >
            {/* <Box>
              <Typography
                variant="subtitle1"
                component="h2"
                gutterBottom
                style={{ paddingTop: "2rem" }}
              >
                Customer Review
              </Typography>
              {[4, 3, 2, 1].map((value, index) => (
                <Box key={index} display="flex" alignItems="center" mb={1}>
                  <Rating value={value} readOnly />
                  <Typography variant="body2">& up</Typography>
                </Box>
              ))}
            </Box> */}

            <Typography
              variant="subtitle1"
              component="h2"
              gutterBottom
              style={{ paddingTop: "2rem" }}
            >
              Categories
            </Typography>
            {categories && <CategoriesComponent categories={categories} />}
          </Container>
        </div>

        
        
        <Container
          style={{
            width: "100%",
            minHeight: "50rem",
            margin: "unset",
          }}
        >
          {products &&
            products.length > 0 &&
            products.map((chunk, chunkIndex) => (
              <div className="carouselContent" key={chunk.id}>
                <div style={{ width: "100%" }}>
                  <SearchCard product={chunk} />
                </div>
              </div>
            ))}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              count={Math.ceil(parseInt(totalProducts) / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
            />
          </div>

          <div style={{ margin: "0 auto" }}>
            {similarproducts && similarproducts.length > 0 ? (
              <Typography
                variant="h5"
                style={{ margin: "0 auto", textAlign: "center",paddingTop:"2rem" }}
              >
                Recommended
              </Typography>
            ) : (
              <LinearLoader/>
              // <Typography
              //   variant="h5"
              //   style={{ margin: "0 auto", textAlign: "center" }}
              // >
              //   We don't have this in stock yet...
              // </Typography>
            )}
          </div>

          {similarproducts &&
            similarproducts.length > 0 &&
            similarproducts.map((chunk, chunkIndex) => (
              <div className="carouselContent" key={chunk.id}>
                <div style={{ width: "100%" }}>
                  <SearchCard product={chunk} />
                </div>
              </div>
            ))}
        </Container>
        
      </div>
      
    </>
  );
};

export default ProductsPage;
