import React, { useState } from "react";
import { Button, Divider, List, ListItem, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Link } from "react-router-dom";
import SearchBar from "./SearchBar";
import FavoriteIcon from "@mui/icons-material/Favorite";
// import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
// import ShoppingCartOutlined from "@mui/icons-material/ShoppingCartOutlined";
import UserButton from "./UserButton.jsx";
import { useSelector } from "react-redux";
import BookIcon from "@mui/icons-material/Book";
import "./header.css";
import PopperPopupState from "./Popper.js";
import ShoppingCart from "@mui/icons-material/ShoppingCart";
import { Grid } from "antd";
import TemporaryDrawer from "./Drawer.jsx";


const AppHeader = (props) => {
  const { user } = useSelector((state) => state.user);
  // const [keyword, setKeyword] = React.useState("");
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const categories = useSelector(
    (state) =>
      state.categories &&
      state.categories.categories &&
      state.categories.categories.data
  );

  const transformCategories = (categories) => {
    return (
      categories &&
      categories.map((category) => ({
        id: category.id,
        name: category.name,
        description: category.children.map((child) => child.name).join(", "),
      }))
    );
  };

  return (
    <>
    <div className="drawer">
    <TemporaryDrawer/>
    </div>


      <div style={{ margin: "0 auto" }} className="header-container">
        <AppBar
          position="static"
          sx={{ backgroundColor: "#c6ac8f" }}
          style={{ boxShadow: "unset", 
        zIndex:"10000000",
        position:"fixed",
          width: "100%",
          
          backgroundColor: "rgb(198 172 142 / 100%)",
          // backgroundColor: "rgb(37 37 37 / 75%)",
          justifyContent: "center",
          display: "flex",
          height:"3.5rem",
          flexWrap: "wrap"
        
        }}
          className="appbar"
        >
          <Toolbar style={{justifyContent:"center",display:"flex",flexWrap:"wrap"}}>
            <div style={{ marginLeft: "1rem", marginRight: "1rem" }}>
              <Typography
                variant="h6"
                sx={{ display: {  sm: "block" } , fontSize: "clamp(0.2rem, 0.3043rem + 3.4783vw, 0.9rem)",textTransform:"capitalize"}}
              >
                <Link
                  to="/"
                  style={{ color: "#1b1303", textDecoration: "none" }}
                >
                  Kitablovers
                </Link>
              </Typography>
            </div>

            <div style={{ width: "55%" }}>
              <SearchBar />
            </div>
            <div>
              <Button
                href="/loadtheboxevent"
                variant="contained"
                className="shineButton"
                style={{ textTransform: "unset", marginLeft: "1rem" }}
              >
                <Typography
                  variant="body1"
                  style={{ color: "black", fontSize: "12px" }}
                >
                  Load the Box Event
                </Typography>
              </Button>
            </div>

            <div style={{ paddingLeft: "1rem" }}>
              <Button href="/cart" style={{ alignItems: "flex-start" }}>
                <ShoppingCart style={{ color: "#1b1303" }} />
              </Button>
            </div>

            <div>
              <Button href="/wishlist" style={{ alignItems: "flex-start" }}>
                <FavoriteIcon style={{ color: "#1b1303" }} />
              </Button>
            </div>
            <UserButton user={user || null} />
          </Toolbar>
        </AppBar>

        <AppBar
          position="static"
          // sx={{ backgroundColor: "#797d62" }}
          // sx={{ backgroundColor: "#dcc8b1" }} // Beige
          // sx={{ backgroundColor: "#8d8a85" }} // Beige
          sx={{ backgroundColor: "#8d8a85" }} // Beige

          // sx={{ backgroundColor: "rgba(141, 138, 133, 0)" }} // Beige
        // sx={{ backgroundColor: "#f4683a" }} // orange
          style={{ boxShadow: "unset",marginTop:"3.5rem" }}
        >
          <Toolbar style={{ all: "unset", display: "flex", margin: "0 auto" ,display:"flex",flexWrap:"wrap",justifyContent:"center"}}>
            <div style={{ paddingLeft: "1rem" }}>
              <Button
                href="/search/?category_id=122"
                style={{
                  alignItems: "flex-start",
                  textTransform: "unset",
                  color: "black",
                }}
              >
                <Typography variant="body1" style={{ fontSize: "12px" ,color:"#f5ebe0"}}>
                  Literary Fiction
                </Typography>
              </Button>
            </div>

            <div style={{ paddingLeft: "1rem" }}>
              <Button
                href="/surprisebox"
                style={{
                  alignItems: "flex-start",
                  textTransform: "unset",
                  color: "black",
                }}
              >
                <Typography variant="body1" style={{ fontSize: "12px" ,color:"#f5ebe0"}}>
                  Surpirse Box
                </Typography>
              </Button>
            </div>

            <div style={{ paddingLeft: "1rem" }}>
              <Button
                href="/search/?category_id=131"
                style={{
                  alignItems: "flex-start",
                  textTransform: "unset",
                  color: "black",
                }}
              >
                <Typography variant="body1" style={{ fontSize: "12px" ,color:"#f5ebe0"}}>
                  Fantasy
                </Typography>
              </Button>
            </div>

            <div style={{ paddingLeft: "1rem" }}>
              <Button
                href="/search/?category_id=125"
                style={{
                  alignItems: "flex-start",
                  textTransform: "unset",
                  color: "black",
                }}
              >
                <Typography variant="body1" style={{ fontSize: "12px" ,color:"#f5ebe0"}}>
                  Thriller
                </Typography>
              </Button>
            </div>
            <div style={{ paddingLeft: "1rem" }}>
              <Button
                href="/search/?category_id=129"
                style={{
                  alignItems: "flex-start",
                  textTransform: "unset",
                  color: "black",
                }}
              >
                <Typography variant="body1" style={{ fontSize: "12px" ,color:"#f5ebe0"}}>
                  Romance
                </Typography>
              </Button>
            </div>
            <div style={{ paddingLeft: "1rem" }}>
              <Button
                href="/search/?category_id=127"
                style={{
                  alignItems: "flex-start",
                  textTransform: "unset",
                  color: "black",
                }}
              >
                <Typography variant="body1" style={{ fontSize: "12px" ,color:"#f5ebe0"}}>
                  Horror
                </Typography>
              </Button>
            </div>
            <div style={{ paddingLeft: "1rem" }}>
              <Button
                href="/search/?category_id=123"
                style={{
                  alignItems: "flex-start",
                  textTransform: "unset",
                  color: "black",
                }}
              >
                <Typography variant="body1" style={{ fontSize: "12px" ,color:"#f5ebe0"}}>
                  Historical Fiction
                </Typography>
              </Button>
            </div>

            <div style={{ paddingLeft: "1rem" }}>
              <Button
                href="/search/?category_id=135"
                style={{
                  alignItems: "flex-start",
                  textTransform: "unset",
                  color: "black",
                }}
              >
                <Typography variant="body1" style={{ fontSize: "12px" ,color:"#f5ebe0"}}>
                  Children's Fiction
                </Typography>
              </Button>
            </div>

            <div style={{ paddingLeft: "1rem" }}>
              <Button
                href="/search/?category_id=137"
                style={{
                  alignItems: "flex-start",
                  textTransform: "unset",
                  color: "black",
                }}
              >
                <Typography variant="body1" style={{ fontSize: "12px" ,color:"#f5ebe0"}}>
                  Biographies
                </Typography>
              </Button>
            </div>

            <div style={{ paddingLeft: "1rem" }}>
              <Button
                href="/search/?category_id=140"
                style={{
                  alignItems: "flex-start",
                  textTransform: "unset",
                  color: "black",
                }}
              >
                <Typography variant="body1" style={{ fontSize: "12px" ,color:"#f5ebe0"}}>
                  Self Help
                </Typography>
              </Button>
            </div>

            <div style={{ paddingLeft: "1rem" }}>
              <Button
                href="/search/?category_id=145"
                style={{
                  alignItems: "flex-start",
                  textTransform: "unset",
                  color: "black",
                }}
              >
                <Typography variant="body1" style={{ fontSize: "12px" ,color:"#f5ebe0"}}>
                  Science
                </Typography>
              </Button>
            </div>

            <div style={{ paddingLeft: "1rem" }}>
              <Button
                href="/search/?category_id=149"
                style={{
                  alignItems: "flex-start",
                  textTransform: "unset",
                  color: "black",
                }}
              >
                <Typography variant="body1" style={{ fontSize: "12px" ,color:"#f5ebe0"}}>
                  Religion and Spirituality
                </Typography>
              </Button>
            </div>

            {transformCategories && (
              <PopperPopupState categories={transformCategories(categories)} />
            )}
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
};

export default AppHeader;
