export const ADD_TO_CART = "ADD_TO_CART";

export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";

export const SAVE_SHIPPING_INFO = "SAVE_SHIPPING_INFO";

// Define your cart constants
export const ADD_TO_CART_AUTHENTICATED = "ADD_TO_CART_AUTHENTICATED";
export const REMOVE_FROM_CART_AUTHENTICATED = "REMOVE_FROM_CART_AUTHENTICATED";
export const CLEAR_CART_AUTHENTICATED = "CLEAR_CART_AUTHENTICATED";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const MODIFY_CART_REQUEST = "MODIFY_CART_REQUEST";
export const MODIFY_CART_SUCCESS = "MODIFY_CART_SUCCESS";
export const MODIFY_CART_FAILURE = "MODIFY_CART_FAILURE";

// actions/types.js

export const GET_CART_REQUEST = "GET_CART_REQUEST";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_FAIL = "GET_CART_FAIL";
