import { Container, Typography } from "@mui/material";
import React from "react";

const Termsandconditions = () => {
  return (
    <>
      <Container>
        <div style={{ padding: "1rem" }}>
          <Typography variant="h5">Terms and Conditions</Typography>
          <div style={{ paddingTop: "2rem" }}>
            <Typography variant="h6">Privacy Policy</Typography>

            <Typography variant="body1">
              www.kitablovers.com app and website is maintained and designed by
              Kitablovers,unit of Bookforest literacy solutions private
              limited.. KITABLOVERS.COM WEBSITE STRICTLY FOCUSES ON THE PRIVACY
              OF OUR CUSTOMERS’ AND OUR LOYALTY TOWARDS OUR COMMITMENT. THE
              POLICY DISCLOSES THE PRACTICE BY WHICH WE GATHER THE NECESSARY
              INFORMATION THROUGH OUR WEBSITE. The Information that is collected
              When you sign up for a Kitablovers.com account or giving your
              contact details in our offline book fair to avail the products &
              services that is offered by our website or Event, we safely store
              all the information that is provided by you. We make use of an
              order form whereby we provide the opportunity to our customers to
              request for information, products & services. We also collect the
              contact information (email address & mobile number) of our
              visitors along with the financial information (credit card & debit
              card). We use contact information to send orders to the customers
              along with the information of our company. The information can
              also be used to communicate with the customers, as and when need
              arises. On the other hand, financial information gathered to
              invoice customer for different kinds of products and services. The
              mailing and promotional calls can be stopped by the user if
              required by following the steps given in Choice/Opt-out below. The
              information of your browser along with your IP address is stored
              in the form of “cookie”. This makes it easy for us to identify you
              and the shopping cart with added products & services. Cookie is a
              tiny data file which is store in your hard drive. Cookie file
              neither read stored in your hard drive nor read the other cookie
              file. You can reject the storage of the cookie in your hard drive
              by simply switching off this option in your browser. For
              purchasing, it’s not necessary to switch on the cookie in the
              browser option. Uses of the collected information In order to
              improve the online customer experience, Kitablovers.com makes use
              of the information collected. Thus when you sign in, it
              authenticate you and send notification through email. Your request
              can be fulfilled for different product & services. We will use the
              information for customizing the advertisements and content
              according to the customer’s need. Information used for capturing
              data insights for internal and external clients. Sharing of
              Information You can be assured that Kitablovers.com does not
              believe in sharing any personal information with any individual,
              company or organization. Information is only shared if we have
              consent from your side unless We are working with some reliable
              partners that agree to accept our terms & conditions and also
              agree to sign a confidentiality agreement. We also respond to
              court orders or any other legal processes in order to exercise or
              establish the legal rights for defending any claims. We may also
              disclose the information of those customers who may come in terms
              of suspicion for any fraud or illegal transaction. Then their
              information will be used for legal processes or investigation by
              the court. This website may have pop-ups of other website and the
              information given to them is not our responsibility Data integrity
              Kitablovers.com collected the customer’s personal information and
              processes it in accordance with the privacy policy. We ensure that
              the data we collected, stored for different process practices
              needed to provide the meaningful online service to the customer.
              Thus, we request our customer’s to provide the data as accurate as
              possible. Security & Confidentiality Kitablovers.com restricts
              access of the personal information to the employees, moderator,
              contractors and agents for updates, delivery and for improvement
              of the product and services. They have to abide to the
              confidentiality agreement failing which they will be terminated.
              We make use of the best security measures for the protection
              against the misuse, loss of information that is under our control.
              We go through the SSL secure commerce server which uses the
              military grade encryption to protect all the information with
              specific codes. Each customer is important to us and we consider
              their approval with due respect. Choice/Opt-out Customers can
              unsubscribe the email at any point of time according to their
              desire by following the suggestion given at the bottom of the
              Kitablovers.com email/newsletter. Contacting Kitablovers.com If
              there is any changes that you would like to make in your current
              information then do remove your previous contact details so that
              you don’t get any updates in your old contacts. You can also be
              with us through the following contacts: Email: cs@Kitablovers.com
              Call: 8860-525-525
            </Typography>
          </div>
          <Typography variant="h6" style={{ paddingTop: "2rem" }}>
            Refund and Return Policy
          </Typography>
          <div>
            <Typography variant="body1">
              Return Policy RETURN IS DEFINED AS THE ACTION OF GIVING BACK THE
              ITEM PURCHASED BY THE BUYER TO THE SELLER. Following situations
              may arise: Book was torn / Pages were missing Incomplete order
              Wrong book was sent by the Seller. We don’t have a Replacement
              copy in stock order Not Received. Return could also result in
              refund of money in most of the above cases. For any other case
              else than the other 5 listed above we don't provide a Full Refund.
              (A) In case of Buyer Ordered a Wrong Item No Problem, We Accept
              Returns in these cases within 3 Days of Date of Delivery. We do
              ask that you please understand that shipping fees will not be
              reimbursed, and the customer is responsible for sending us the
              book back at our address in the same condition. Also please
              include a short note mentioning your order number and a short
              description for our Assistance. Once the return is received the
              refund will be processed in 3 Days and you can expect it in your
              same payment method within 7 days. Please send the return to:
              Kitablovers.com ADDRESS :Kh no 20/29 Nanglipoona village, Landmark
              Near cooperative bank Delhi 110036 (B) Not Satisfied with the
              Quality There is no escaping the fact that most of the books we
              sell are Used Pre Owned Books. So they will certainly show some
              signs of usage and Normal Wear, Such as the Name of the Previous
              Owner Written, The cover of the Book Showing Normal Creases or
              Dents, Spine having Creases. All these are what we call Normal
              Signs of Usage and we offer Replacements on these Books. Or if the
              Replacement Copy is not available what we do is give you the
              freedom to choose some other book in place of that book. Or a
              Partial Refund to compensate the Damage. Security &
              Confidentiality Kitablovers.com restricts access of the personal
              information to the employees, moderator, contractors and agents
              for updates, delivery and for improvement of the product and
              services. They have to abide to the confidentiality agreement
              failing which they will be terminated. We make use of the best
              security measures for the protection against the misuse, loss of
              information that is under our control. We go through the SSL
              secure commerce server which uses the military grade encryption to
              protect all the information with specific codes. Each customer is
              important to us and we consider their approval with due respect.
              (C) Book Cover is Different We try our best so that the Image on
              the Site matches with Actual Cover of the Book. But in a few
              Products these may be different. If you want to make the purchase
              for Collection purpose. Please get in touch with us over WhatsApp
              or Email so that we can share the actual image of the Cover of the
              book for you. We Sell Pre Owned Books and Track & Upload all of
              our Books by ISBN Number. The Thing is the Books Cover are changed
              from time to time by Publishers. But the ISBN and Contents remain
              same in all books except Textbooks. So there may be a possibility
              the Book we have in hand was published in 2015 and now the cover
              for that book has been changed by the Publisher but the ISBN and
              Content will have not been changed. So you may be shown a
              different cover on the website but the Book and Content is going
              to be the same. The Book Picture is for illustrative purpose only,
              Actual Cover may vary. We don’t accept returns in this Case. We
              check each and every Book before shipping it out. (D) Following
              books shall not be eligible for return or replacement: Damages due
              to misuse of book; Any consumable item which has been
              used/installed; Books with tampered or missing serial/UPC numbers;
              Any Book with Different Cover. Please Contact us to get the actual
              images if ordering for a collection. You are getting the book
              cheaper somewhere else. Thanks for your interest in us. For any
              other query please contact us. We Accept Cancellation of Orders.
              If your order has been dispatched or Picked up by our Courier
              Partner then it can no longer be cancelled. If it's Dispatched or
              picked up It can be only Returned Not Cancelled.
            </Typography>
          </div>

          <div style={{paddingTop:"2rem"}}>
            <Typography variant="h6">Shipping Policy</Typography>
            Once order is recieved products will be shipped within 2 to 5 business days.
          </div>
        </div>
      </Container>
    </>
  );
};

export default Termsandconditions;
