import { BASE_URL } from "../App";
import {
  ADD_TO_CART_AUTHENTICATED,
  CLEAR_CART_AUTHENTICATED,
  GET_CART_FAIL,
  GET_CART_REQUEST,
  GET_CART_SUCCESS,
  MODIFY_CART_FAILURE,
  MODIFY_CART_REQUEST,
  MODIFY_CART_SUCCESS,
  REMOVE_CART_ITEM,
  REMOVE_FROM_CART_AUTHENTICATED,
  SAVE_SHIPPING_INFO,
} from "../constants/cartConstants";
import axios from "axios";

// const BASE_URL = "https://webbackend.kitablovers.com";
// const BASE_URL = "http://webapptest.kitablovers.com";

// const BASE_URL = "http://localhost:4000";

export const getCart = () => async (dispatch) => {
  try {
    dispatch({ type: GET_CART_REQUEST });

    // Make the API call with the correct Axios configuration
    const response = await axios.post(
      BASE_URL + "/app/middleware/v1/getcart",
      {},
      {
        withCredentials: true, // Include this option in the Axios configuration
      }
    );

    if (response.status === 200) {
      dispatch({ type: GET_CART_SUCCESS, payload: response.data });
    } else {
      dispatch({ type: GET_CART_FAIL, payload: response.data?.message });
    }
  } catch (error) {
    dispatch({ type: GET_CART_FAIL, payload: error.message });
  }
};

// export const modifyCart = (user_id, product_variant_id, qty) => {
//   return async (dispatch) => {
//     try {
//       dispatch({ type: MODIFY_CART_REQUEST });

//       const myHeaders = new Headers();
//       myHeaders.append("Content-Type", "application/json");

//       const requestBody = {
//         user_id: parseInt(user_id),
//         product_variant_id: parseInt(product_variant_id),
//         qty: qty,
//       };

//       const requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: JSON.stringify(requestBody),
//       };

//       const response = await fetch(
//         BASE_URL + "/app/middleware/v1/manage_cart",
//         requestOptions,
//         {
//           withCredentials: true, // Include this option in the Axios configuration
//         }
//       );
//       const data = await response.json();

//       dispatch({ type: "MODIFY_CART_SUCCESS", payload: data });
//     } catch (error) {
//       console.error("Error modifying cart:", error);
//       dispatch({ type: "MODIFY_CART_FAILURE", payload: error.message });
//     }
//   };
// };

export const modifyCart = (user_id, product_variant_id, qty) => {
  return async (dispatch) => {
    try {
      // Validate input parameters here if necessary

      dispatch({ type: MODIFY_CART_REQUEST });

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestBody = {
        user_id: parseInt(user_id),
        product_variant_id: parseInt(product_variant_id),
        qty: qty,
      };

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        credentials: "include", // Correct option for including credentials in fetch
      };

      const response = await fetch(
        `${BASE_URL}/app/middleware/v1/manage_cart`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Server responded with an error"); // Handle non-2xx responses
      }

      const data = await response.json();
      dispatch({ type: MODIFY_CART_SUCCESS, payload: data });
    } catch (error) {
      console.error("Error modifying cart:", error);
      dispatch({ type: MODIFY_CART_FAILURE, payload: error.message });
    }
  };
};

// REMOVE FROM CART
export const removeItemsFromCart = (id) => async (dispatch, getState) => {
  dispatch({
    type: REMOVE_CART_ITEM,
    payload: id,
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

// SAVE SHIPPING INFO
export const saveShippingInfo = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_SHIPPING_INFO,
    payload: data,
  });

  localStorage.setItem("shippingInfo", JSON.stringify(data));
};

// Action to add a product to the cart
export const addToCartAuthenticate = (product) => (dispatch) => {
  dispatch({
    type: ADD_TO_CART_AUTHENTICATED,
    payload: product,
  });
};

// Action to remove a product from the cart
export const removeFromCartAuthenticate = (productId) => (dispatch) => {
  dispatch({
    type: REMOVE_FROM_CART_AUTHENTICATED,
    payload: productId,
  });
};

// Action to clear the entire cart
export const clearCartAuthenticate = () => (dispatch) => {
  dispatch({
    type: CLEAR_CART_AUTHENTICATED,
  });
};
