import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { IconButton } from '@mui/material';


const AddAddressCard = ({ onAdd }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // minHeight: 100,
        height:264,
        minWidth: 345,
        // height: '100%',
        borderStyle: 'dashed',
        marginTop: 2,
        marginBottom: 2,
         marginRight:2,
        padding: 2
      }}

      
    >
      <CardContent sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <IconButton onClick={onAdd} color="primary" href='/address'>
          <AddCircleOutlineIcon sx={{ fontSize: 40 }} />
        </IconButton>
        <Typography variant="subtitle1" component="div">
          Add address
        </Typography>
      </CardContent>
    </Card>
  );
};


export default AddAddressCard