// import React, { useState, useEffect } from "react";
// import { Box, Card, CardMedia, ButtonBase, Skeleton } from "@mui/material";

// const ProductCarousel = ({ product }) => {
//   const placeholderImage = "https://via.placeholder.com/400";
//   const [mainImage, setMainImage] = useState(null); // Start with null
//   const [loading, setLoading] = useState(true);

//   // useEffect(() => {
//   //   // Immediately set mainImage to null when the product changes
//   //   setMainImage(null);
//   //   setLoading(true);

//   //   if (product) {
//   //     const imageToLoad = new Image();
//   //     const imageUrl =
//   //       product.image || product.other_images?.[0] || placeholderImage;
//   //     imageToLoad.src = imageUrl;

//   //     imageToLoad.onload = () => {
//   //       setMainImage(imageUrl);
//   //       setLoading(false);
//   //     };
//   //   }
//   // }, [product]);

//   useEffect(() => {
//     // Set loading to true immediately when the product changes
//     setLoading(true);

//     if (product) {
//       // Check if the main image is already included in the other_images array
//       const allImages = product.other_images.includes(product.image)
//         ? [...product.other_images]
//         : [product.image, ...product.other_images];

//       // Use the first image as the main image initially
//       const imageToLoad = new Image();
//       imageToLoad.src = allImages[0] || placeholderImage;

//       imageToLoad.onload = () => {
//         setMainImage(allImages[0]);
//         setLoading(false);
//       };
//     }
//   }, [product]);


//   const handleThumbnailClick = (imageSrc) => {
//     setLoading(true);
//     const imageToLoad = new Image();
//     imageToLoad.src = imageSrc;

//     imageToLoad.onload = () => {
//       setMainImage(imageSrc);
//       setLoading(false);
//     };
//   };

//   const thumbnails = product?.other_images?.length
//     ? product.other_images
//     : [placeholderImage];

//   return (
//     <Card style={{borderRadius:"0", boxShadow:"unset", margin:"2rem",objectFit:"contain"}}>
//       {loading || !mainImage ? ( 
//         <Skeleton variant="rectangular" width="100%" height={400} />
//       ) : (
//         <CardMedia
//         style={{objectFit:"contain"}}
//           component="img"
//           height="400"
//           image={mainImage}
//           alt={product?.name || "Product Image"}
//         />
//       )}
//       <Box sx={{ display: "flex", justifyContent: "center", padding: "10px" }}>
//         {thumbnails.map((image, index) => (
//           <ButtonBase key={index} onClick={() => handleThumbnailClick(image)}>
//             <CardMedia
//               style={{objectFit:"contain"}}
//               component="img"
//               sx={{ width: "64px", height: "64px", margin: "0 10px" }}
//               image={image}
//               alt={`Thumbnail ${index + 1}`}
//             />
//           </ButtonBase>
//         ))}
//       </Box>
//     </Card>
//   );
// };

// export default ProductCarousel;

import React, { useState, useEffect } from 'react';
import { Box, Card, CardMedia, ButtonBase, Skeleton } from '@mui/material';

const ProductCarousel = ({ product }) => {
  const placeholderImage = "https://via.placeholder.com/400";
  const [mainImage, setMainImage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Set loading to true immediately when the product changes
    setLoading(true);

    if (product) {
      // Check if the main image is already included in the other_images array
      const allImages = product.other_images.includes(product.image)
        ? [...product.other_images]
        : [product.image, ...product.other_images];

      // Use the first image as the main image initially
      const imageToLoad = new Image();
      imageToLoad.src = allImages[0] || placeholderImage;

      imageToLoad.onload = () => {
        setMainImage(allImages[0]);
        setLoading(false);
      };
    }
  }, [product]);

  const handleThumbnailClick = (imageSrc) => {
    setLoading(true);
    const imageToLoad = new Image();
    imageToLoad.src = imageSrc;

    imageToLoad.onload = () => {
      setMainImage(imageSrc);
      setLoading(false);
    };
  };

  // Ensure that the main image is included in the thumbnails
  const thumbnails = product
    ? product.other_images.includes(product.image)
      ? product.other_images
      : [product.image, ...product.other_images]
    : [placeholderImage];

  return (
    <Card  sx={{
      borderRadius: '0',
      boxShadow: 'unset',
      margin: '2rem',
      padding: '2rem',
      '@media (max-width: 600px)': {
        padding: '0px',
        margin: '0px',
      },
    }}
    >
      {loading || !mainImage ? (
        <Skeleton variant="rectangular" width="100%" height={300} />
      ) : (
        <CardMedia
        style={{objectFit:"contain",paddingTop:"1rem"}}
          component="img"
          height="300"
          image={mainImage}
          alt={product?.name || "Product Image"}
          
        />
      )}
      <Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px', '@media (max-width: 600px)': {
    display: 'none',
  } }}>
        {thumbnails.map((image, index) => (
          <ButtonBase key={index} onClick={() => handleThumbnailClick(image)}>
            <CardMedia
            style={{objectFit:"contain"}}
              component="img"
              sx={{ width: '64px', height: '64px', margin: '0 10px' }}
              image={image}
              alt={`Thumbnail ${index + 1}`}
            />
          </ButtonBase>
        ))}
      </Box>
    </Card>
  );
};

export default ProductCarousel;
