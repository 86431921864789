import React, { useEffect, useState } from 'react'
import './EventsPage.css'; // Assume your styles are in EventsPage.css
import { Button, Container } from '@mui/material';
import Eventcard from './Eventcard';
import { BASE_URL } from '../../App';


  // const eventsData = [
  //   {

  //     id: "1",
  //     product_id: "120",
  //     title: "indore book events",
  //     sub_title: "at bawarkua",
  //     type: "1",
  //     to_date: "2024-01-21",
  //     from_date: "2024-01-26",
  //     description: "<p>A book event usually involves a bookstore presence but it rethinks the venue and broadens the scope beyond a mere signing. For example, the event for a book with a huge art component might be held at a local art museum with a local book vendor on site selling books.</p>",
  //     contact_info: "",
  //     map_link: "https://maps.app.goo.gl/WKAvHpVRCEMSeARc6"
  //   },
  //   {id:2,
      
  //     product_id: "120",
  //     title: "indore book events",
  //     sub_title: "at bawarkua",
  //     type: "1",
  //     to_date: "2024-01-21",
  //     from_date: "2024-01-26",
  //     description: "<p>A book event usually involves a bookstore presence but it rethinks the venue and broadens the scope beyond a mere signing. For example, the event for a book with a huge art component might be held at a local art museum with a local book vendor on site selling books.</p>",
  //     contact_info: "",
  //     map_link: "https://maps.app.goo.gl/WKAvHpVRCEMSeARc6"
  //   },
  //   {id:3,
      
  //     product_id: "120",
  //     title: "indore book events",
  //     sub_title: "at bawarkua",
  //     type: "1",
  //     to_date: "2024-01-21",
  //     from_date: "2024-01-26",
  //     description: "<p>A book event usually involves a bookstore presence but it rethinks the venue and broadens the scope beyond a mere signing. For example, the event for a book with a huge art component might be held at a local art museum with a local book vendor on site selling books.</p>",
  //     contact_info: "",
  //     map_link: "https://maps.app.goo.gl/WKAvHpVRCEMSeARc6"
  //   },
  //   {
  //     id:4,
  //     mode: 'Offline',
  //     venue: 'Phoenix Marketcity, Viman Nagar Rd',
  //     address: 'Viman Nagar, Pune, Maharashtra 411014',
  //     dates: 'Wed, 07th Feb, 2024 - Sun, 11th Feb, 2024'
  //   },
  //   // ... more events
  // ];

  const LoadtheboxEvent = () => {

    const [events, setEvents] = useState([]);

    useEffect(() => {
      fetch(BASE_URL+'/app/middleware/v1/events')
        .then(response => response.json())
        .then(data => setEvents(data.events))
        .catch(error => console.error('Error fetching data:', error));
    }, []);

    useEffect(() => {
      // console.log("events",events)
    }, [events])
    
    const [viewPast, setViewPast] = useState(false);

    const toggleEvents = () => {
      setViewPast(!viewPast);
  };

  useEffect(() => {
  fetch(BASE_URL+'/app/middleware/v1/events')
    .then(response => response.json())
    .then(data => {
      const sortedEvents = data.events.sort((a, b) => {
        const dateA = new Date(a.from_date), dateB = new Date(b.from_date);
        return dateA - dateB; // For ascending order
      });
      setEvents(sortedEvents);
    })
    .catch(error => console.error('Error fetching data:', error));
}, []);


  // const getFilteredEvents = () => {
  //     const currentDate = new Date();
  //     return events?.filter(event => {
  //         const toDate = new Date(event.from_date);
  //         return viewPast ? toDate < currentDate : toDate >= currentDate;
  //     });
  // };

//   const getFilteredEvents = () => {
//     const currentDate = new Date();
//     return events?.filter(event => {
//         // Assuming event.from_date is in "YYYY-DD-MM" format
//         const dateParts = event.from_date.split("-");
//         // Correctly parsing the date as "YYYY-MM-DD"
//         const toDate = new Date(`${dateParts[0]}-${dateParts[2]}-${dateParts[1]}`);
        
//         return viewPast ? toDate < currentDate : toDate >= currentDate;
//     });
// };
const getFilteredEvents = () => {
  const currentDate = new Date();
  return events?.filter(event => {
      const toDate = new Date(event.to_date);
      return viewPast ? toDate < currentDate : toDate >= currentDate;
  });
};


  return (
    <>
    <Container style={{ minHeight: "55rem" }}>
        <div className="events-page">
            <Button variant='contained' onClick={toggleEvents} style={{marginTop:"2rem",backgroundColor:"#88909d"}}>
                {viewPast ? 'View Current & Upcoming Events' : 'View Past Events'}
            </Button>
            <header className="events-header">
                {viewPast ? 'Past Events' : 'Upcoming Events'}
            </header>
            <section className="events-container">
                {getFilteredEvents()?.map((event, index) => (
                    <Eventcard key={index} event={event} past={viewPast} />
                ))}
            </section>
        </div>
    </Container>
</>
  )
}

export default LoadtheboxEvent