import React, { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../App";
// import instamojo from "../../image/instamojo.webp";
// import "../paypal/paypal.css";

const InstaMojo = () => {
  const [token, setToken] = useState("");
  const [form, setForm] = useState({
    amount: "",
    name: "",
    email: "",
    phone: "",
  });
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [loadingPayment2, setLoadingPayment2] = useState(false);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const getToken = (e) => {
    e.preventDefault();
    setLoadingPayment(true);
    axios
      .post(BASE_URL + "/app/payment/v1/get-token")
      .then((res) => {
        setToken(res.data);
        setLoadingPayment(false);
      })
      .catch((error) => {
        setLoadingPayment(false);
        console.error(error);
      });
  };

  const handlePayment = () => {
    setLoadingPayment2(true);
    axios
      .post(BASE_URL + "/app/payment/v1/create-order", { ...form, token })
      .then((res) => {
        setLoadingPayment2(false);
        window.open(res.data, "_blank");
      })
      .catch((error) => {
        setLoadingPayment2(false);
        console.error(error);
      });
  };
  return (
    <>
      <div className="main">
        {/* <img width={300} src={instamojo} alt="" /> */}
        <p>Payment Gateway integration</p>
        <p>{token}</p>
        <div className="card px-5 py-4 mt-5">
          <form className="" onSubmit={getToken}>
            <label htmlFor="#" className="mt-2">
              Name:
            </label>
            <div className="col-12 center">
              <input required type="text" name="name" onChange={handleChange} />
            </div>
            <label htmlFor="#" className="mt-2">
              Email:
            </label>
            <div className="col-12 center">
              <input
                required
                type="email"
                name="email"
                onChange={handleChange}
              />
            </div>
            <label htmlFor="#" className="mt-2">
              Phone:
            </label>
            <div className="col-12 center">
              <input
                required
                type="text"
                name="phone"
                onChange={handleChange}
              />
            </div>
            <label htmlFor="#" className="mt-2">
              Amount:
            </label>
            <div className="col-12 center">
              <input
                required
                type="text"
                name="amount"
                onChange={handleChange}
              />
            </div>
            {!loadingPayment ? (
              <div className="col-12 center">
                <button className="w-100" type="submit">
                  Get Token
                </button>
              </div>
            ) : (
              <div className="col-12 center">
                <button className="w-100 text-center" type="submit">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">LoadingPayment...</span>
                  </div>
                </button>
              </div>
            )}
          </form>
          {!loadingPayment2 ? (
            <div className="col-12 center">
              <button className="w-100" onClick={handlePayment}>
                Pay Now
              </button>
            </div>
          ) : (
            <div className="col-12 center">
              <button className="w-100 text-center" type="submit">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">LoadingPayment...</span>
                </div>
              </button>
            </div>
          )}
        </div>
      </div>
      <p>@codesense24</p>
    </>
  );
};

export default InstaMojo;
