import React from 'react'
import { useParams } from 'react-router-dom';

const PaymentSuccess = () => {
    const {id} = useParams();
  return (
    <>
    <div>PaymentSuccess</div>
    {id}
    </>
    
  )
}

export default PaymentSuccess