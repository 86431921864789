import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Box,
  Container,
  InputAdornment,
  IconButton,
  Tooltip,
  Link,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { BASE_URL } from "../../App";
import OtpInput from "react-otp-input";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { Toaster, toast } from "react-hot-toast";
import { auth } from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, register } from "../../actions/userAction";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";

const SignupForm = () => {
  const recaptchaContainerRef = useRef(null);
  const [serverError, setServerError] = useState("");

  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false); // Add state to track OTP verification

  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (registrationSuccess) {
      toast.success("Registration successful!");
      const timer = setTimeout(() => {
        navigate("/");
      }, 3000); // Redirect after 3 seconds

      return () => clearTimeout(timer);
    }
  }, [registrationSuccess]);

  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const userError = useSelector((state) => state.user.error);

  const checkUserExists = async (mobileNumber, callback) => {
    try {
      const response = await fetch(BASE_URL + "/app/middleware/v1/verifyuser", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ mobile: mobileNumber }),
      });
      const data = await response.json();
      if (data.error) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          mobile: "User already exists with this mobile number",
        }));
        setIsSubmitting(false);
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, mobile: "" }));
      }
      callback();
    } catch (error) {
      console.error("Error checking user existence", error);
    }
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.name = formData.name ? "" : "This field is required";
    tempErrors.mobile = /^[0-9]{10}$/.test(formData.mobile)
      ? ""
      : "Invalid phone number";
    tempErrors.email = /$^|.+@.+..+/.test(formData.email)
      ? ""
      : "Email is not valid";
    tempErrors.password = formData.password ? "" : "This field is required";
    tempErrors.confirmPassword =
      formData.password == formData.confirmPassword
        ? ""
        : "Passwords do not match";
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (validate()) {
  //     checkUserExists(formData.mobile, () => {
  //       if (Object.values(errors).every((x) => x === "")) {
  //         setIsSubmitting(true);
  //         console.log("Form data submitted:", formData);
  //       }
  //     });
  //   }
  // };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      // Only check for user existence if there are no errors with the mobile number
      if (!errors.mobile) {
        checkUserExists(formData.mobile, () => {
          if (Object.values(errors).every((x) => x === "")) {
            setIsSubmitting(true);
            console.log("Form data submitted:", formData);
          }
        });
      }
    }
  };
  

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   if (name === "mobile") {
  //     checkUserExists(value);
  //   }
  //   let newErrors = { ...errors };
  //   if (name === "password" || name === "confirmPassword") {
  //     delete newErrors.password;
  //     delete newErrors.confirmPassword;
  //   }

  //   setFormData({ ...formData, [name]: value });
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  
    let newErrors = { ...errors };
    if (name === "mobile") {
      // Validate mobile number format before checking user existence
      if (/^[0-9]{10}$/.test(value)) {
        checkUserExists(value); // Only check if the format is valid
        newErrors.mobile = "";
      } else {
        newErrors.mobile = "Invalid phone number";
      }
    }
  
    if (name === "password" || name === "confirmPassword") {
      delete newErrors.password;
      delete newErrors.confirmPassword;
    }
  
    setErrors(newErrors);
  };

  

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // PHONE AUTHENTICATION
  function onCaptchaVerify() {
    if (!window.RecaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          // size: "normal",
          callback: (response) => {
            verifyphonenumber();
          },
          "expired-callback": () => {},
        }
      );
    }
  }

  // function verifyphonenumber(event) {
  //   event.preventDefault();
  //   setLoading(true);
  //   onCaptchaVerify();
  //   const appVerifier = window.recaptchaVerifier;
  //   const mobile = "+91" + formData.mobile;

  //   signInWithPhoneNumber(auth, mobile, appVerifier)
  //     .then((confirmationResult) => {
  //       window.confirmationResult = confirmationResult;
  //       setLoading(false);
  //       setShowOtp(true);
  //       toast.success("OTP Sent Successfully");
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       toast.error(error.message);
  //     });
  // }

  function verifyphonenumber(event) {
    event.preventDefault();
    
    // Check if there are any mobile number errors before sending the OTP
    if(errors.mobile) {
      toast.error("Please correct the errors before sending OTP.");
      return; // Exit the function if there are errors
    }
  
    setLoading(true);
    onCaptchaVerify();
    const appVerifier = window.recaptchaVerifier;
    const mobile = "+91" + formData.mobile;
  
    signInWithPhoneNumber(auth, mobile, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOtp(true);
        toast.success("OTP Sent Successfully");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  }

  
  const triggerRegistration = () => {
    dispatch(register({ ...formData, country_code: "+91" }));
  };

  function onOtpverify() {
    window.confirmationResult
      .confirm(otp)
      .then(async (result) => {
        setUser(result.user);
        setLoading(false);
        setShowOtp(false); // Hide OTP input
        setIsOtpVerified(true); // Set OTP verification to true
        await triggerRegistration(); // Call registration function after OTP is verified
        await setRegistrationSuccess(true);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }
  const hasErrors = errors => Object.values(errors).some(error => error);


  return (
    <Container
      style={{ minHeight: "55rem", maxWidth: "40rem", paddingTop: "2rem" }}
    >
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          autoComplete="name"
          autoFocus
          value={formData.name}
          onChange={handleChange}
          error={!!errors.name}
          helperText={errors.name}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="mobile"
          label="Phone Number"
          name="mobile"
          autoComplete="tel"
          value={formData.mobile}
          onChange={handleChange}
          error={!!errors.mobile}
          helperText={errors.mobile}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={formData.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          id="password"
          autoComplete="current-password"
          value={formData.password}
          onChange={handleChange}
          error={!!errors.password}
          helperText={errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={togglePasswordVisibility}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="confirmPassword"
          label="Confirm Password"
          type={showConfirmPassword ? "text" : "password"}
          id="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle confirm password visibility"
                  onClick={toggleConfirmPasswordVisibility}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {Object.values(errors).some((error) => error) && (
          <Typography color="error" sx={{ mb: 2 }}>
            Please resolve the above errors before submitting.
          </Typography>
        )}

        {userError && (
          <Typography color="error" sx={{ mt: 2 }}>
            {userError}
          </Typography>
        )}

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={hasErrors(errors)} 
        >
          Sign Up
        </Button>
      </Box>

      {isSubmitting && (
        <div className={`d-flex justify-content-center`}>
          <Toaster toastOptions={{ duration: 4000 }} />
          {!user ? (
            <div
              className={`row position-absolute mt-5 bg-dark text-white p-5`}
            >
              {showOtp ? (
                <div>
                  <Typography
                    style={{
                      paddingBottom: "2rem",
                      paddingTop: "2rem",
                    }}
                  >
                    Enter Your OTP{" "}
                  </Typography>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    shouldAutoFocus
                    renderInput={(props) => (
                      <input
                        {...props}
                        style={{ width: "30px", marginRight: "12px" }}
                      />
                    )}
                  />
                  <div
                    className="d-flex justify-content-center"
                    style={{ paddingTop: "2rem" }}
                  >
                    <Button
                      className="btn btn-primary mt-3 w-75 "
                      onClick={onOtpverify}
                      disabled={loading}
                      variant="contained"
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm mr-2"></span>
                      )}
                      Verify OTP
                    </Button>
                  </div>
                </div>
              ) : (
                <div style={{ paddingTop: "2rem" }}>
                  <Typography style={{ paddingBottom: "2rem" }}>
                    Verify Your Phone Number
                  </Typography>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      paddingTop: "2rem",
                    }}
                  >
                    <PhoneInput
                      country={"in"}
                      value={"+91" + formData.mobile}
                      // onChange={setPh}
                      disabled={true}
                    />
                  </div>

                  <div
                    className="d-flex justify-content-center"
                    style={{
                      paddingTop: "2rem",
                      paddingBottom: "2rem",
                    }}
                  >
                    <Tooltip title="OTP SMS WILL BE SENT TO YOUR PHONE NUMBER">
                      <Button
                        className="btn btn-primary mt-3 w-75 "
                        onClick={verifyphonenumber}
                        disabled={loading}
                        variant="contained"
                      >
                        {loading && (
                          <span className="spinner-border spinner-border-sm mr-2"></span>
                        )}
                        Send OTP
                      </Button>
                    </Tooltip>
                  </div>
                  {/* <div id="recaptcha-container" className="mt-4"></div> */}
                  <div
                    id="recaptcha-container"
                    style={{ marginBottom: "20px" }}
                  ></div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      )}
      <div>
        
        <Link href="/login" variant="body2">
                  {"Already have an account? Login"}
                </Link>
      </div>
    </Container>
  );
};

export default SignupForm;
